export const hideSavedStateAfterTimeout = (
  isSaved: boolean,
  setIsSaved: (state: boolean) => void,
) => {
  let timer;
  if (isSaved) {
    setIsSaved(true);

    timer = setTimeout(() => {
      setIsSaved(false);
      clearTimeout(timer);
    }, 3500);
  }

  return timer;
};
