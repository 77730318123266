const WEB_SHOP: { [key: string]: { [key: string]: string } } = {
  kramp: {
    DEV: 'ci.kramp.com',
    STG: 'webtest.kramp.com',
    PRD: 'www.kramp.com',
  },
  sdf: {
    DEV: 'agricenter-webtest.kramp.com',
    STG: 'agricenter-webtest.kramp.com',
    PRD: 'www.agricenter.com',
  },
  johndeere: {
    DEV: 'deere-webtest.kramp.com',
    STG: 'deere-webtest.kramp.com',
    PRD: 'extraparts.deere.com',
  },
  raiffeisen: {
    DEV: 'raiffeisen-parts-webtest.kramp.com',
    STG: 'raiffeisen-parts-webtest.kramp.com',
    PRD: 'www.raiffeisen-parts.de',
  },
  rwzcologne: {
    DEV: 'rwz-parts-webtest.kramp.com',
    STG: 'rwz-parts-webtest.kramp.com',
    PRD: 'www.rwz-parts.com',
  },
  aparts: {
    DEV: 'agtparts-webtest.kramp.com',
    STG: 'agtparts-webtest.kramp.com',
    PRD: 'www.agtparts.eu',
  },
};

export const getWebShopUrl = (ci: string, env: string) => {
  const lowerCaseCi = ci ? ci.toLowerCase() : 'kramp';
  const cleanedCi = lowerCaseCi === 'krampgroup' ? 'kramp' : lowerCaseCi;
  const verifiedCi = Object.keys(WEB_SHOP).includes(cleanedCi)
    ? cleanedCi
    : 'kramp';
  return WEB_SHOP[verifiedCi][env.toUpperCase()];
};
