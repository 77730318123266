// Returns the value of a query parameter

import { ParsedUrlQuery } from 'querystring';

// If the parameter occurs >1 times, the value of the first occurrence is returned.
export const getQueryValue = (
  query: ParsedUrlQuery,
  parameter: string,
): string | undefined => {
  const value = query[parameter];

  if (!value) return;

  return typeof value === 'string' ? value : value[0];
};
