import React from 'react';
import { NextPageContext } from 'next';
import App, { AppContext } from 'next/app';
import getConfig from 'next/config';
import { Router } from 'next/router';
import { parseCookies } from 'nookies';
import { getCacheConfig } from '@dc3/data-access/client-state';
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  from,
  InMemoryCache,
  NormalizedCacheObject,
  TypePolicy,
} from '@apollo/client';
import {
  getHeadersFromContext,
  getHeadersFromProps,
  GraphQLRequestHeaders,
} from '../headers';
import { getHttpLink } from '../links/httpLink';
import { onError } from '@apollo/client/link/error';
import * as Sentry from '@sentry/nextjs';
type NextPageContextApp = NextPageContextWithApollo & AppContext;
interface NextPageContextWithApollo extends NextPageContext {
  apolloClient: ApolloClient<NormalizedCacheObject> | null;
  apolloState: NormalizedCacheObject;
  ctx: NextPageContextApp;
}
const { serverRuntimeConfig, publicRuntimeConfig } = getConfig();
const { GRAPHQL_URL } = serverRuntimeConfig;
const { APOLLO_DEV_TOOLS_ENABLED, BASE_PATH } = publicRuntimeConfig;
let globalApolloClient: ApolloClient<NormalizedCacheObject> | null = null;

const errorLink = onError(({ operation, graphQLErrors, networkError }) => {
  Sentry.withScope((scope) => {
    scope.setTransactionName(operation.operationName);
    scope.setContext('apolloGraphQLOperation', {
      operationName: operation.operationName,
      variables: JSON.stringify(operation.variables),
      extensions: JSON.stringify(operation.extensions),
    });
    graphQLErrors?.forEach((error) => {
      Sentry.captureMessage(error.message, {
        level: 'error',
        fingerprint: ['{{ default }}', '{{ transaction }}'],
        contexts: {
          apolloGraphQLError: {
            error,
            message: error.message,
            extensions: error.extensions,
          },
        },
      });
    });
    if (networkError) {
      Sentry.captureMessage(networkError.message, {
        level: 'error',
        contexts: {
          apolloNetworkError: {
            error: networkError,
            extensions: (networkError as any).extensions,
          },
        },
      });
    }
  });
});

const createApolloClient = (
  initialState = {},
  ctx: NextPageContext | undefined,
  headers: GraphQLRequestHeaders,
  localCacheFields: TypePolicy['fields'],
  links: ApolloLink[],
) => {
  const SERVER = Boolean(ctx);
  const httpLink = getHttpLink(headers, GRAPHQL_URL, BASE_PATH);
  return new ApolloClient({
    cache: new InMemoryCache(getCacheConfig(localCacheFields)).restore(
      initialState,
    ),
    ssrMode: SERVER, // Disables forceFetch on the server (so queries are only run once)
    link: from([...links, errorLink, httpLink]),
    connectToDevTools: APOLLO_DEV_TOOLS_ENABLED,
  });
};
export const initApolloClient = (
  initialState: NormalizedCacheObject = {},
  ctx: NextPageContext | undefined,
  headers: GraphQLRequestHeaders,
  cache: TypePolicy['fields'] = {},
  links: ApolloLink[] = [],
) => {
  // Make sure to create a new client for every server-side request so that data
  // isn't shared between connections (which would be bad)
  if (typeof window === 'undefined') {
    return createApolloClient(initialState, ctx, headers, cache, links);
  }
  // Reuse client on the client-side
  if (!globalApolloClient) {
    globalApolloClient = createApolloClient(
      initialState,
      ctx,
      headers,
      cache,
      links,
    );
  }
  return globalApolloClient;
};
const initOnContext = (
  ctx: NextPageContextApp,
  localCacheFields: TypePolicy['fields'],
  links: ApolloLink[],
): NextPageContextApp => {
  const inAppContext = Boolean(ctx.ctx);
  // We consider installing `withApollo({ ssr: true })` on global App level
  // as antipattern since it disables project wide Automatic Static Optimization.
  if (process.env.NODE_ENV === 'development') {
    if (inAppContext) {
      console.warn(
        'Warning: You have opted-out of Automatic Static Optimization due to `withApollo` in `pages/_app`. \n' +
          'Read more: https://err.sh/next.js/opt-out-auto-static-optimization\n',
      );
    }
  }
  // Initialize ApolloClient if not already done
  const apolloClient =
    ctx.apolloClient ||
    initApolloClient(
      ctx.apolloState || {},
      inAppContext ? ctx.ctx : ctx,
      getHeadersFromContext(inAppContext ? ctx.ctx : ctx),
      localCacheFields,
      links,
    );
  // We send the Apollo Client as a prop to the component to avoid calling initApollo() twice in the server.
  // Otherwise, the component would have to call initApollo() again but this
  // time without the context. Once that happens, the following code will make sure we send
  // the prop as `null` to the browser.
  (
    apolloClient as ApolloClient<NormalizedCacheObject> & {
      toJSON: () => null;
    }
  ).toJSON = () => null;
  // Add apolloClient to NextPageContext & NextAppContext.
  // This allows us to consume the apolloClient inside our
  // custom `getInitialProps({ apolloClient })`.
  ctx.apolloClient = apolloClient;
  if (inAppContext) {
    ctx.ctx.apolloClient = apolloClient;
  }
  return ctx;
};
interface WithApolloOptions {
  ssr?: boolean;
  cache?: TypePolicy['fields'];
  links?: ApolloLink[];
}
export const withApollo = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  PageComponent: any,
  { ssr = true, cache = {}, links = [] }: WithApolloOptions = {},
) => {
  const WithApollo = ({
    apolloClient,
    apolloState,
    ...pageProps
  }: {
    apolloClient: ApolloClient<NormalizedCacheObject>;
    apolloState: NormalizedCacheObject;
  }) => {
    const client =
      apolloClient ||
      initApolloClient(
        apolloState,
        undefined,
        getHeadersFromProps(pageProps as { router: Router }),
        cache,
        links,
      );
    return (
      <ApolloProvider client={client}>
        <PageComponent {...pageProps} />
      </ApolloProvider>
    );
  };
  // Set the correct displayName in development
  if (process.env.NODE_ENV !== 'production') {
    const displayName =
      PageComponent.displayName || PageComponent.name || 'Component';
    WithApollo.displayName = `withApollo(${displayName})`;
  }
  if (ssr || PageComponent.getInitialProps) {
    WithApollo.getInitialProps = async (
      ctx: NextPageContextApp,
    ): Promise<object> => {
      const inAppContext = Boolean(ctx.ctx);
      const { apolloClient } = initOnContext(ctx, cache, links);
      // Run wrapped getInitialProps methods
      let pageProps = {};
      if (PageComponent.getInitialProps) {
        pageProps = await PageComponent.getInitialProps(ctx);
      } else if (inAppContext) {
        pageProps = await App.getInitialProps(ctx);
      }
      // Only on the server:
      if (typeof window === 'undefined') {
        const { AppTree } = ctx;
        // When redirecting, the response is finished.
        // No point in continuing to render
        if (ctx?.res?.finished) {
          return pageProps;
        }
        // Only if dataFromTree is enabled
        if (ssr && AppTree) {
          try {
            // Run all GraphQL queries
            const { getDataFromTree } = await import(
              '@apollo/client/react/ssr'
            );
            // Since AppComponents and PageComponents have different context types
            // we need to modify their props a little.
            let props;
            if (inAppContext) {
              props = { ...pageProps, apolloClient };
            } else {
              props = { pageProps: { ...pageProps, apolloClient } };
            }
            const { idToken } = parseCookies(ctx.ctx);
            if (idToken) {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              await getDataFromTree(<AppTree {...props} />);
            }
          } catch (error) {
            // Prevent Apollo Client GraphQL errors from crashing SSR.
            // Handle them in components via the data.error prop:
            // https://www.apollographql.com/docs/react/api/react-apollo.html#graphql-query-data-error
            console.error('Error while running `getDataFromTree`', error);
          }
        }
      }
      return {
        ...pageProps,
        // Extract query data from the Apollo store
        apolloState: apolloClient?.cache.extract(),
        // Provide the client for ssr. As soon as this payload
        // gets JSON.stringified it will remove itself.
        apolloClient: ctx.apolloClient,
      };
    };
  }
  return WithApollo;
};
export { getHeadersFromContext };
