import React, { useCallback, useEffect, useState } from 'react';
import { Box } from '@dc3/ui/box';
import { Input, StyledTextFieldProps } from '../input/Input';
import InputAdornment from '@mui/material/InputAdornment';
import { Flag } from '@dc3/ui/flag';
import { debounce, displayLocaleName } from '@dc3/utils/helpers';
import { useIntl } from 'react-intl';
import { getCountryForLanguage } from './LocalizedInputs.utils';
import { DEBOUNCE_TIMEOUT_MS } from '@dc3/utils/constants';
import {
  Control,
  Controller,
  FieldValues,
  UseFormSetValue,
} from 'react-hook-form';

type TextFieldPropsWithoutLabel = Omit<StyledTextFieldProps, 'label'>;
type LocalizedInputsProps = TextFieldPropsWithoutLabel & {
  /**
   * Pass in array of the locales supported by the Tenant's webshop in the format `de`
   */
  supportedTenantLanguages: string[];
  control: Control;
  setValue?: UseFormSetValue<FieldValues>;
  /** Expected values in object should have format like { 'nl': 'value', 'da': 'value' } */
  values?: Record<string, string>;
  errors?: unknown;
  onDisplayNameChange?: (value: string, locale: string) => void;
};

type LocalizedInputProp = TextFieldPropsWithoutLabel & {
  languageCode: string;
  control: Control;
  setValue?: UseFormSetValue<FieldValues>;
  value: string | unknown;
  onDisplayNameChange: (value: string, locale: string) => void;
};

export const INPUT_NAME_PREFIX = 'localizedInput-';

export const LocalizedInputs = ({
  supportedTenantLanguages,
  control,
  setValue,
  values = {},
  errors,
  onDisplayNameChange,
  ...rest
}: LocalizedInputsProps) => (
  <>
    {supportedTenantLanguages.map((languageCode: string) => (
      <LocalizedInput
        languageCode={languageCode}
        control={control}
        setValue={setValue}
        value={values[languageCode]}
        key={languageCode}
        onDisplayNameChange={onDisplayNameChange}
        error={Boolean(errors?.[`${INPUT_NAME_PREFIX}${languageCode}`])}
        {...rest}
      />
    ))}
  </>
);

const LocalizedInput = ({
  languageCode,
  control,
  setValue,
  value,
  onDisplayNameChange,
  InputProps,
  ...rest
}: LocalizedInputProp) => {
  const { locale: uiLocale } = useIntl();
  const [displayName, setDisplayName] = useState(value);

  const languageDisplayName = displayLocaleName({
    displayLanguage: uiLocale,
    code: languageCode,
    type: 'language',
  });

  useEffect(() => {
    setDisplayName(value);
  }, [value]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedCallback = useCallback(
    debounce(
      (output: string) => onDisplayNameChange(output.trim(), languageCode),
      DEBOUNCE_TIMEOUT_MS,
    ),
    [debounce, onDisplayNameChange],
  );

  const handleChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = target.value;
    if (newValue !== displayName && onDisplayNameChange) {
      debouncedCallback(newValue);
    }

    setDisplayName(newValue);
  };

  useEffect(() => {
    if (value) setValue(INPUT_NAME_PREFIX + languageCode, value);
  }, [value]);

  return (
    <Box marginBottom={2} key={languageCode}>
      <Controller
        control={control}
        name={`${INPUT_NAME_PREFIX}${languageCode}`}
        render={({ field: { onChange, value, name, ref } }) => (
          <Input
            id={name}
            name={name}
            label={languageDisplayName}
            value={value || ''}
            onChange={(e) => {
              handleChange(e as React.ChangeEvent<HTMLInputElement>);
              onChange(e.target.value as string);
            }}
            InputProps={{
              ...InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <Flag
                    countryCode={
                      getCountryForLanguage[languageCode] || languageCode
                    }
                    countryName={languageDisplayName}
                  />
                </InputAdornment>
              ),
            }}
            // have to explicitly define variant to prevent this material bug from happening: https://github.com/mui-org/material-ui/issues/15697#issuecomment-493419773
            variant="outlined"
            inputRef={ref}
          />
        )}
      />
    </Box>
  );
};
