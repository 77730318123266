import { GraphQLRequestHeaders } from '../headers';
import fetch from 'isomorphic-unfetch';
import { HttpLink } from '@apollo/client';
import { isClientSide } from '@dc3/utils/helpers';

export const getHttpLink = (
  headers: GraphQLRequestHeaders,
  graphqlUrl: string,
  BASE_PATH: string,
) =>
  new HttpLink({
    uri: isClientSide()
      ? `${window.location.origin}${BASE_PATH}/api/graphql`
      : graphqlUrl,
    fetch,
    headers,
  });
