import kramp from './lib/kramp/config';
import maykers from './lib/maykers/config';
import remoteConfig from './lib/kramp/remote.json';

export { kramp, maykers, remoteConfig };
export * from './lib/types';
export * from './lib/countries';
export * from './lib/kramp/types';
export * from './lib/kramp/config.utils';
export * from './lib/default-proposal-from-emails';
