import { InMemoryCacheConfig } from '@apollo/client';
import { sharedClientSate } from './init-shared-state';

export const getCacheConfig = (
  localCacheFields: any = [],
): InMemoryCacheConfig => ({
  typePolicies: {
    Query: {
      fields: {
        ...sharedClientSate,
        ...localCacheFields,
        get_detailed_freight_charge: {
          merge(existing, incoming) {
            return { ...existing, ...incoming };
          },
        },
      },
    },
    ItemSuggestion: {
      keyFields: ['item_id'],
    },
    Quotation: {
      fields: {
        validation_errors: {
          merge(_, incoming = []) {
            return [...incoming];
          },
        },
        quotation_lines: {
          merge(_, incoming = []) {
            return [...incoming];
          },
        },
      },
    },
  },
});
