import {
  pageAccessControl as krampPageAccessControl,
  featureConfiguration as krampFeatureConfiguration,
} from './tenant-access-control/kramp';

interface AccessControl {
  [key: string]: {
    [key: string]: Record<string, string[]>;
  };
}

export const accessControlConfiguration: AccessControl = {
  kramp: {
    pageAccessControl: krampPageAccessControl,
    featureConfiguration: krampFeatureConfiguration,
  },
};
