import { NextPageContext } from 'next';
import { parseCookies } from 'nookies';
import { decodeJwt } from './refresh-token';
import { getCountryByField } from '@dc3/utils/helpers';

export const userDataMiddleware = (ctx: NextPageContext) => {
  const { idToken, DC3_LOCALE, DC3_VIEW_MODE } = parseCookies(ctx);
  const preSelectedCountry = getCountryByField('locale', DC3_LOCALE);
  const jwt = idToken ? decodeJwt(idToken) : null;

  return {
    userData: jwt
      ? {
          accountId: jwt.acc,
          username: jwt.log,
          userId: jwt.user_id,
          organizationRoles: jwt.rol,
          organizationId: jwt.org,
          corporateIdentityCountryCode: jwt.ccc,
        }
      : null,
    country: preSelectedCountry,
    viewMode: DC3_VIEW_MODE,
  };
};

export interface UserData {
  accountId: string;
  userId: string;
  username: string;
  organizationId: string;
  corporateIdentityCountryCode: Record<string, string[]>;
  organizationRoles: Record<string, string[]>;
}
