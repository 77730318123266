import { GQLClassification } from '@dc3/data-access/graphql';
import { useUserData } from '@dc3/utils/auth';

enum ClassificationCodes {
  ADVISORY = 'advisory',
}

interface ClassificationProps {
  classifications: GQLClassification[];
  country: string;
}

const hasAdvisoryClassification = ({
  classifications,
  country,
}: ClassificationProps): boolean =>
  classifications.some(
    ({ code, values }) =>
      code === ClassificationCodes.ADVISORY &&
      values.some(
        ({ key, value }) => key === country.toUpperCase() && value === 'true',
      ),
  );

interface UseIsAdvisoryItemProps {
  classifications: GQLClassification[];
}

export const useIsAdvisoryItem = ({
  classifications = [],
}: UseIsAdvisoryItemProps) => {
  const userData = useUserData();
  const code = userData?.country?.code;

  return classifications && code
    ? hasAdvisoryClassification({
        classifications,
        country: code,
      })
    : false;
};
