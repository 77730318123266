import { NextPageContext } from 'next';
import { parseCookies } from 'nookies';
import getConfig from 'next/config';
import url from 'url';

export const authenticationMiddleware = (ctx: NextPageContext) => {
  // Only run server-side since we can't read the http-only idToken cookie client-side
  const { req, res } = ctx;
  if (req && res) {
    const { publicRuntimeConfig } = getConfig();
    const { idToken } = parseCookies(ctx);

    if (!idToken) {
      // Checks if it's the home page
      if (ctx && publicRuntimeConfig.BASE_PATH && req.url) {
        const parsedUrl = url.parse(req.url, true);
        let redirectTo = parsedUrl.query.redirectTo || '';

        // If redirectTo already exists, prepend the current URL to it
        if (redirectTo) {
          redirectTo = `${encodeURIComponent(req.url)}&${redirectTo}`;
        } else {
          redirectTo = encodeURIComponent(req.url);
        }

        const redirectLocation = `/?redirectTo=${publicRuntimeConfig.BASE_PATH}${redirectTo}`;

        res.writeHead(302, { Location: redirectLocation });
        res.end();
      }
    }
  }
};
