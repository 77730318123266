import React, { forwardRef } from 'react';
import MaterialButton, {
  ButtonProps as MaterialButtonProps,
} from '@mui/material/Button';
import MaterialFab from '@mui/material/Fab';
import MaterialIconButton from '@mui/material/IconButton';
import { InlineLoading } from '@dc3/ui/loading';
import { Box } from '@mui/material';

export type ButtonMode = 'primary' | 'secondary';

interface ButtonProps extends MaterialButtonProps {
  mode?: ButtonMode;
  // have to define target since it's supported but not recognized by TS
  target?: string;
  icon?: MaterialButtonProps['startIcon'];
  component?: React.ElementType;
  isLoading?: boolean;
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (props: ButtonProps, ref) => {
    const {
      isLoading = false,
      mode = 'primary',
      component = 'button',
      icon,
      disabled,
      ...rest
    } = props;
    const variant = mode === 'primary' ? 'contained' : 'outlined';
    const mergedProps: MaterialButtonProps = {
      variant,
      color: 'primary',
      // only show spinner if a default icon is selected to prevent width changes on button
      ...(icon && {
        startIcon: isLoading ? (
          // Size is 20 to keep with defualt size of the icon and prevent width change
          <Box width={20} display="inline-flex" component="span">
            <InlineLoading size={16} color="inherit" />
          </Box>
        ) : (
          icon
        ),
      }),
      ...rest,
    };
    // component prop comes from OverridableComponent
    return (
      <MaterialButton
        component={component}
        {...mergedProps}
        disabled={isLoading || disabled}
        ref={ref}
      />
    );
  },
);
Button.displayName = 'Button';
export const IconButton = MaterialIconButton;
export const Fab = MaterialFab;
