import { colors as dc3Colors } from './dc3.colors';
import { colors as dc3DarkColors } from './dc3-dark.colors';

export enum ViewModes {
  DC3Light = 'DC3',
  DC3Dark = 'DC3-Dark',
}

export type Brand = ViewModes.DC3Light | ViewModes.DC3Dark | undefined;

export const getBrandColors = (brand?: Brand) => {
  switch (brand) {
    case ViewModes.DC3Light:
      return dc3Colors;
    case ViewModes.DC3Dark:
      return dc3DarkColors;
    default:
      return dc3Colors;
  }
};
