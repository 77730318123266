import { darken, alpha } from '@mui/material';
import { AppColors, CustomPalette } from './brand.model';

// don't export this.
const dc3Colors = {
  grayLight: '#3C3F4C',
  grayMiddle: '#282A32',
  grayDark: '#141416',

  white: '#FFFFFF',

  primaryLight: '#ABE3DF',
  primary: '#57C6BF',
  primaryDark: '#3CB1A9',

  secondaryLight: '#C5E4FE',
  secondary: '#5BA7FC',
  secondaryDark: '#5BA7FC',

  errorBackground: '#331212',
  warningBackground: '#332b12',
};

const appColors: AppColors = {
  krampRed: '#ae0000',
  sidebar: '#27262C',
  bgLogin: '#313134',
  topBar: '#313134',
  topBarHome: '#313134',
  contentBackground: {
    subtle: darken(dc3Colors.grayLight, 0.11),
    default: dc3Colors.grayLight,
    primary: dc3Colors.grayDark,
    error: dc3Colors.errorBackground,
    warning: dc3Colors.warningBackground,
  },
  loadingOverlay: alpha(dc3Colors.grayMiddle, 0.3),
};

export const colors: CustomPalette = {
  appColors,
  palette: {
    mode: 'dark',
    background: {
      default: dc3Colors.grayDark,
      paper: dc3Colors.grayMiddle,
    },
    text: {
      primary: dc3Colors.white,
      secondary: 'rgba(255, 255, 255, 0.7)',
      disabled: 'rgba(255, 255, 255, 0.5)',
    },
    primary: {
      light: dc3Colors.primaryLight,
      main: dc3Colors.primary,
      dark: dc3Colors.primaryDark,
    },
    secondary: {
      light: dc3Colors.secondaryLight,
      main: dc3Colors.secondary,
      dark: dc3Colors.secondaryDark,
    },
  },
};
