import React, { useMemo } from 'react';
import { getEnvironmentVariable } from '@dc3/utils/constants';
import { TenantConfigurationContext } from './TenantConfigurationContext';
import * as allTenants from '@dc3/utils/tenant-config';
import { isFeatureVisible } from './TenantConfiguration.utils';
import {
  PageKeys,
  ParsedFeatures,
  TenantConfiguration,
} from '@dc3/utils/tenant-config';
import { useUserData } from '@dc3/utils/auth';

type Tenants = Record<string, TenantConfiguration>;

interface TenantConfigurationProviderProps {
  tenant: string;
  countryCode: string | undefined;
  fallbackComponent: React.FC<{ requiredPermissions?: string[] }>;
  children?: React.ReactNode;
  features?: Partial<ParsedFeatures>;
}

export const TenantConfigurationProvider = ({
  tenant,
  countryCode = '',
  fallbackComponent: Forbidden,
  children,
}: TenantConfigurationProviderProps) => {
  const env = getEnvironmentVariable('ENV')?.toLowerCase() || 'dev';
  const app = (getEnvironmentVariable('BASE_PATH') || '/') as PageKeys;
  const { userData } = useUserData();

  const userRoles = useMemo(
    () =>
      userData?.organizationRoles?.[userData?.organizationId]?.map((r) => +r),
    [userData],
  );

  const tenants = allTenants as unknown as Tenants;
  const tenantConfiguration = tenants[tenant];

  const canViewPage = isFeatureVisible(
    tenantConfiguration.pages[app],
    env,
    countryCode,
    userRoles,
  );

  return (
    <TenantConfigurationContext.Provider value={tenantConfiguration}>
      {canViewPage ? children : <Forbidden />}
    </TenantConfigurationContext.Provider>
  );
};
