import { getEnvironmentVariable } from '@dc3/utils/constants';
import { useCallback } from 'react';

const isProduction = getEnvironmentVariable('ENV')?.toLowerCase() === 'prd';

interface GTMEvent {
  [key: string]: string | number | boolean;
  event: string;
}

export const useGTM = () => {
  const trackEvent = useCallback((event: GTMEvent) => {
    if (!isProduction) {
      return;
    }

    const dataLayer =
      (typeof window !== 'undefined' && (window as any).dataLayer) || [];
    dataLayer.push({
      ...event,
      // prevents default recursive merge https://github.com/google/data-layer-helper#preventing-default-recursive-merge
      _clear: true,
    });
  }, []);

  return {
    trackEvent,
  };
};
