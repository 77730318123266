import * as Sentry from '@sentry/nextjs';
import getConfig from 'next/config';
import { sentryIgnoreList } from './sentry-ignore-list';

export const enrichBreadcrumb = (
  breadcrumb: Sentry.Breadcrumb,
  hint?: Sentry.BreadcrumbHint,
): Sentry.Breadcrumb => {
  // adds graphql operation name to breadcrumbs
  if (breadcrumb.category === 'fetch') {
    const graphQLStringQuery = (hint?.['input'] || [])[1]?.body;
    const graphQLHeaders = (hint?.['input'] || [])[1]?.headers;
    const graphQLQuery = graphQLStringQuery && JSON.parse(graphQLStringQuery);
    const operationName = graphQLQuery?.operationName || '';
    breadcrumb.data = {
      ...breadcrumb.data,
      ...(graphQLQuery?.operationName && { operationName }),
      ...(graphQLQuery?.operationName && { graphQLHeaders: graphQLHeaders }),
      ...(graphQLQuery?.operationName && {
        graphQLRequest: graphQLStringQuery,
      }),
    };
  }

  return breadcrumb;
};

export const initSentry = (dsn: string) => {
  try {
    const {
      publicRuntimeConfig: { SENTRY_RELEASE_NAME, ENV },
    } = getConfig();

    Sentry.init({
      dsn,
      release: SENTRY_RELEASE_NAME,
      beforeBreadcrumb: enrichBreadcrumb,
      environment: ENV,
      enabled: ENV === 'prd',
      tracesSampleRate: 0.1,
      replaysSessionSampleRate: 0.1,
      ignoreErrors: sentryIgnoreList,
      replaysOnErrorSampleRate: 1,
      integrations: [],
    });
  } catch (error) {
    console.error('Failed to initialize sentry');
  }
};
