import { css } from '@emotion/react';
import { Theme } from '@dc3/utils/theme';
import styled from '@emotion/styled';

export const coreCSS = (theme: Theme) => css`
  body {
    font-family: 'Roboto', sans-serif;
    background-color: ${theme.appColors.bgLogin};
  }
`;
export const StyledLink = styled.a`
  color: gray;
  text-decoration: underline;
  cursor: pointer;
`;
