import { useEffect, useRef } from 'react';

export const useLazyEffect: typeof useEffect = (cb, dependencies) => {
  const initializeRef = useRef<boolean>(false);

  useEffect(
    (...args) => {
      if (initializeRef.current) {
        cb(...args);
      } else {
        initializeRef.current = true;
      }
    },
    // disabled check because dependencies are inserted elsewhere
    // also, adding cb to the dependencies will freeze the app on selecting an organization
    //eslint-disable-next-line
    dependencies,
  );
};
