import { useUserData } from '@dc3/utils/auth';
import { LOCALE } from '@dc3/utils/constants';

enum LocaleOrigin {
  'INTERFACE',
  'SELECTED_COUNTRY',
}

export const useGetLocaleHeader = (
  useLocaleFrom: LocaleOrigin = LocaleOrigin.INTERFACE,
) => {
  const { country } = useUserData();

  // default value is LocaleType.SELECTED_COUNTRY
  let locale = country?.locale;

  if (useLocaleFrom === LocaleOrigin.INTERFACE) {
    // hardcoded for now since we only support one language. https://kramphub.atlassian.net/browse/DC3-1632
    locale = 'en_GB';
  }

  return {
    headers: {
      [LOCALE]: locale,
    },
  };
};
