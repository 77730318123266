import { getEnvironmentVariable } from '@dc3/utils/constants';
import { Country } from '@dc3/utils/tenant-config';
import { Brand, getTheme, ViewModes } from '@dc3/utils/theme';
import { getTranslations } from '@dc3/utils/translations';
import { ThemeProvider as MaterialThemeProviderV5 } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import React, { useState } from 'react';
import { IntlProvider } from 'react-intl';
import { SnackBarProvider } from '@dc3/ui/snackbar';
import { UserData, UserDataContext } from '@dc3/utils/auth';
import { AccessControlProvider } from './AccessControl';
import { ErrorHandlerProvider } from './ErrorHandlerProvider';
import { SecretsProvider } from './Secrets';
import { TenantConfigurationProvider } from './TenantConfiguration/TenantConfigurationProvider';
import { FeatureTogglesProvider } from '@dc3/feature-toggles';

interface AppProviderProps {
  userData: {
    userData: UserData;
    country: Country;
    viewMode?: ViewModes;
  };
  errorComponent: React.FC | React.VFC;
  forbiddenComponent: React.FC | React.VFC;
  secrets: Record<string, string>;
  permissions?: string[];
  children?: React.ReactNode;
  isAuthenticated: boolean;
}

export const AppProvider = ({
  errorComponent,
  forbiddenComponent,
  userData,
  secrets,
  permissions,
  children,
  isAuthenticated,
}: AppProviderProps) => {
  const tenant = getEnvironmentVariable('TENANT');
  const initialViewMode = userData.viewMode ?? ViewModes.DC3Light;
  const [country, setCountry] = useState(userData?.country);

  const [language, messages] = getTranslations(
    userData?.country?.locale,
    tenant,
  );

  const [viewMode, setViewMode] = useState<Brand>(initialViewMode);

  return (
    <IntlProvider locale={language} messages={messages}>
      <UserDataContext.Provider
        value={{
          ...userData,
          viewMode,
          isAuthenticated,
          setViewMode,
          country,
          setCountry,
        }}
      >
        <MaterialThemeProviderV5 theme={getTheme(viewMode)}>
          <TenantConfigurationProvider
            countryCode={userData?.country?.code}
            tenant={tenant}
            fallbackComponent={forbiddenComponent}
          >
            <AccessControlProvider
              tenant={tenant}
              userPermissions={permissions}
              fallbackComponent={forbiddenComponent}
            >
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <SecretsProvider secrets={secrets}>
                  <ErrorHandlerProvider fallbackComponent={errorComponent}>
                    <FeatureTogglesProvider>
                      <SnackBarProvider>{children}</SnackBarProvider>
                    </FeatureTogglesProvider>
                  </ErrorHandlerProvider>
                </SecretsProvider>
              </LocalizationProvider>
            </AccessControlProvider>
          </TenantConfigurationProvider>
        </MaterialThemeProviderV5>
      </UserDataContext.Provider>
    </IntlProvider>
  );
};
