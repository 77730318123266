/* eslint-disable @typescript-eslint/no-var-requires */
import getConfig from 'next/config';

const { serverRuntimeConfig } = getConfig();

export const projectName = `projects/${serverRuntimeConfig.SECRET_PROJECT_ID}`;
export const secretName = serverRuntimeConfig.SECRET_NAME;

export const getClient = () => {
  if (typeof window === 'undefined') {
    const {
      SecretManagerServiceClient,
    } = require('@google-cloud/secret-manager');

    return new SecretManagerServiceClient();
  }
};
