import {
  GQLSharedClientStateQuery,
  useSharedClientStateQuery,
} from './queries/shared.client-query.generated';
import { sharedStateMutations } from './mutations';

interface SharedState {
  state: GQLSharedClientStateQuery;
  actions: typeof sharedStateMutations;
}

export const useSharedState = (): SharedState => {
  const { data } = useSharedClientStateQuery();
  return {
    state: data as GQLSharedClientStateQuery,
    actions: sharedStateMutations,
  };
};
