export enum ComponentName {
  CREATE_USER = 'createUser',
  HOLD_TAB = 'holdTab',
  OVERVIEW_TAB = 'overviewTab',
  PROPOSAL_TAB = 'proposalTab',
  PENDING_TAB = 'pendingTab',
  QUOTATION_LINE = 'quotationLine',
  QUICK_ADD_PRODUCT = 'quickAddProduct',
  BULK_ADD_Product = 'bulkAddProduct',
  CONFIGURABLE_ADD = 'configurableAddProduct',
  QUOTATION_ACTION_MODAL = 'QuotationActionModal',
}
