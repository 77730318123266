import React from 'react';
import { StyledLoading, LoadingBox } from './Loading.styles';
import CircularProgress, {
  CircularProgressProps,
} from '@mui/material/CircularProgress';

interface LoadingProps {
  message?: string;
  // progress accept a value in the range 0 - 100. Use a normalize function for other ranges.
  progress?: number;
}

export const Loading = ({ message, progress = 0 }: LoadingProps) => {
  const hasProgress = progress > 0;
  const progressProps: CircularProgressProps = hasProgress
    ? { value: progress, variant: 'determinate' }
    : null;
  return (
    <StyledLoading>
      <CircularProgress color="secondary" {...progressProps} />
      {(message || hasProgress) && (
        <LoadingBox>{message || `${Math.round(progress)}%`}</LoadingBox>
      )}
    </StyledLoading>
  );
};

export const InlineLoading = CircularProgress;
