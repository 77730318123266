import Cookies from 'js-cookie';
import { DC3_LOCALE, LOCALE, CORPORATE_IDENTITY } from '@dc3/utils/constants';
import { UseI18nResponse } from '@dc3/utils/hooks';

export const getLoginHeaders = (): Record<string, string> => {
  const userLocale = Cookies.get(DC3_LOCALE);
  const localeHeader: Record<string, string> = userLocale
    ? { [LOCALE]: userLocale }
    : {};

  return {
    ...localeHeader,
    [CORPORATE_IDENTITY]: 'kramp',
  };
};

export const mapErrorMessage = (
  error: any,
  translate: UseI18nResponse,
): string => {
  let errorMessage = '';
  if (error?.response?.data?.errors?.length > 0) {
    switch (error.response.data.errors[0]) {
      case 'Authentication failed':
        errorMessage = translate('login.authenticationFailed');
        break;
      case 'The account is disabled':
        errorMessage = translate('login.accountDisabled');
        break;
      case 'The account is blocked due to too many failed attempts':
        errorMessage = translate('login.accountBlocked');
        break;
      default:
        errorMessage = translate('login.unknownAuthenticationError');
    }
  } else {
    errorMessage = translate('login.unknownAuthenticationError');
  }

  return errorMessage;
};
