import React, { useEffect, useState } from 'react';
import { CountryCode, Country } from '@dc3/utils/tenant-config';

import { Flag } from '@dc3/ui/flag';
import MaterialMenu from '@mui/material/Menu';
import MaterialMenuItem from '@mui/material/MenuItem';
import { FlagButton, StyledButton } from './CountrySelector.styles';
import { Icon } from '@dc3/ui/icon';
import { Tooltip } from '@dc3/ui/tooltip';
import { Select } from '../select/Select';
import { useI18n } from '@dc3/utils/hooks';
import { useSnackBar } from '@dc3/ui/snackbar';
import { getCountries, getCountryByField } from '@dc3/utils/helpers';
import { useTheme } from '@dc3/utils/theme';
import { Loading } from '@dc3/ui/loading';
import { Box } from '@dc3/ui/box';

interface CountrySelectorProps {
  value?: CountryCode;
  onCountryChange: (country: Country) => void;
  mode: 'iconOnly' | 'select';
  isLoading?: boolean;
}

export const CountrySelector = ({
  value,
  onCountryChange,
  mode = 'select',
  isLoading = false,
}: CountrySelectorProps) => {
  const theme = useTheme();
  const translate = useI18n('global');
  const { setSnackBar } = useSnackBar();
  const countries = getCountries();
  // value can be undefined
  const country = getCountryByField('code', value);
  const noneSelected = !isLoading && !country;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => setAnchorEl(null);

  const handleCountryOnClick = (countryName = 'GB') => {
    const selectedCountry = getCountryByField('name', countryName);

    if (selectedCountry) {
      onCountryChange(selectedCountry);
    }
  };

  const getCountryMenuItem = (countryItem: {
    code: CountryCode;
    name: string;
  }) => (
    <>
      <Flag
        countryCode={countryItem.code}
        countryName={countryItem.name}
        isMenuItem
        data-testid={`country-${countryItem.code}`}
      />
      &nbsp;
      {countryItem.name}
    </>
  );

  useEffect(() => {
    if (noneSelected) {
      setSnackBar({
        type: 'error',
        text: translate('error.pleaseSelectCountry'),
      });
    }
    // never use translation as a dependency
    // eslint-disable-next-line
  }, [country, setSnackBar]);

  return (
    <>
      {mode === 'iconOnly' && (
        <>
          <Tooltip variant="primary" title={translate('header.selectCountry')}>
            <>
              <FlagButton onClick={handleClick} theme={theme}>
                {isLoading && !country && <Loading />}
                {country && !isLoading && (
                  <Box data-testid="select-country">
                    <Flag
                      countryCode={country.code}
                      countryName={country.name}
                    />
                  </Box>
                )}
              </FlagButton>
              {noneSelected && (
                <Box color="black" mb={2}>
                  <StyledButton
                    size="medium"
                    color="error"
                    startIcon={<Icon color={'error'}>warning</Icon>}
                    onClick={handleClick}
                  >
                    {translate('header.selectCountry')}
                  </StyledButton>
                </Box>
              )}
            </>
          </Tooltip>

          <MaterialMenu
            id="country-select-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {countries.map((countryItem, i) => (
              <MaterialMenuItem
                key={i}
                onClick={() => handleCountryOnClick(countryItem.name)}
              >
                {getCountryMenuItem(countryItem)}
              </MaterialMenuItem>
            ))}
          </MaterialMenu>
        </>
      )}

      {mode === 'select' && (
        <Select
          label={translate('header.selectCountry')}
          value={country?.name}
          name="country-select"
          options={countries.map((countryItem) => ({
            value: countryItem?.name,
            label: getCountryMenuItem(countryItem),
          }))}
          onChange={(e) => handleCountryOnClick(e.target.value as string)}
        />
      )}
    </>
  );
};
