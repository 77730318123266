import React from 'react';
import { SecretsContext, SecretsContextValues } from './SecretsContext';

export const SecretsProvider = ({
  children,
  secrets,
}: SecretsContextValues & {
  children?: React.ReactNode;
}) => (
  <SecretsContext.Provider value={{ secrets }}>
    {children}
  </SecretsContext.Provider>
);
