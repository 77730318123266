import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

export const usePageLoading = (): boolean => {
  const router = useRouter();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const handleStart = (url: string) => {
      !(url === `${window.location.pathname}${window.location.search}`) &&
        setLoading(true);
    };
    const handleComplete = (url: string) => {
      url === `${window.location.pathname}${window.location.search}` &&
        setLoading(false);
    };

    router.events.on('routeChangeStart', handleStart);
    router.events.on('routeChangeComplete', handleComplete);
    router.events.on('routeChangeError', handleComplete);

    return () => {
      router.events.off('routeChangeStart', handleStart);
      router.events.off('routeChangeComplete', handleComplete);
      router.events.off('routeChangeError', handleComplete);
    };
  }, [router.events]);

  return loading;
};
