import { parseCookies } from 'nookies';
import { DEVELOPMENT } from '@dc3/utils/constants';

export const getFullPathFromRouter = (
  basePath: string | undefined,
  asPath: string | undefined,
) => {
  const applicationPath = asPath !== '/' ? asPath : '';
  const fullPath = `${basePath}${applicationPath}`;
  return fullPath;
};
export const getPagePermissions = (
  pageAccessControl: Record<string, string[]>,
  fullPath: string,
) => {
  const matchingConfigProperty = pageAccessControl
    ? Object.keys(pageAccessControl).find((configKey) =>
        fullPath.startsWith(configKey),
      ) || ''
    : '';

  return matchingConfigProperty
    ? pageAccessControl?.[matchingConfigProperty]
    : [];
};

export const canAccess = (
  permissions: string[],
  requiredPermissions: string[],
) => {
  if (!requiredPermissions || !requiredPermissions.length) {
    return true;
  }

  return permissions.some((role: string) => requiredPermissions.includes(role));
};

export const shouldByPassAccessControl = () => {
  // dont access access control in case is manually disabled through a cookie
  const { DC3_ACCESS_CONTROL } = parseCookies() || {};
  const isAccessControlDisabled = +DC3_ACCESS_CONTROL === 0;

  if (!isAccessControlDisabled) {
    return false;
  }

  return DEVELOPMENT;
};

export const toggleAccessControlScript = `
  function toggleAccessControl(value) {
    document.cookie = 'DC3_ACCESS_CONTROL=' + value;
    window.location.reload();
  };

  window.disableAccessControl = function() {
    toggleAccessControl(0);
  };

  window.enableAccessControl = function() {
    toggleAccessControl(1);
  };
`.replace(/\n\s{2,}/g, '');
