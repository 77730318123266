import styled from '@emotion/styled';
import { css } from '@emotion/react';
import MaterialIcon from '@mui/material/Icon';

const fontSizeMapping = {
  small: '16px',
  default: '24px',
  medium: '32px',
  large: '48px',
};

const coloredIconCss = ({
  customColor,
  fontSize,
}: {
  customColor?: string;
  fontSize?: 'inherit' | 'small' | 'default' | 'large' | 'medium';
}) => css`
  ${customColor && `color: ${customColor};`}
  /* Setting font-size prop doesn't work on native component so override it here */
  ${fontSize &&
  fontSize !== 'inherit' &&
  `font-size: ${fontSizeMapping[fontSize]}`}
`;

export const StyledIcon = styled(MaterialIcon, {
  // prevent DOM error unknown prop error
  shouldForwardProp: (prop) => prop !== 'customColor',
})`
  ${coloredIconCss}
`;
