import { NextPageContext } from 'next';
import getConfig from 'next/config';
import { Router } from 'next/router';
import { GraphQLRequestHeaders } from './graphql-request-headers';
import {
  AUTH_TOKEN_KEY,
  DC3_LOCALE,
  getQueryValue,
  CORPORATE_IDENTITY,
  GRAPHQL_TOKEN_KEY,
  LOCALE,
  X_API_KEY,
  X_CLOUD_DEBUG,
  X_RID,
  CHANNEL,
  CHANNEL_TYPE,
  X_DC3_APP_NAME,
} from '@dc3/utils/constants';
import { getCookieValue } from '@dc3/utils/helpers';

const getShortId = () => Math.random().toString(36).substr(2, 9);

export const getHeadersFromContext = ({
  req,
  query,
}: NextPageContext): GraphQLRequestHeaders => {
  const { serverRuntimeConfig, publicRuntimeConfig } = getConfig();
  const { ALEXIS_KEY } = serverRuntimeConfig;
  const { CHANNEL_VALUE, CHANNEL_TYPE_VALUE, TENANT, APP_NAME } =
    publicRuntimeConfig;
  const headers: Partial<GraphQLRequestHeaders> = {
    [CORPORATE_IDENTITY]: TENANT || 'kramp',
    [X_DC3_APP_NAME]: APP_NAME || 'dc3-app-name',
    [X_API_KEY]: ALEXIS_KEY,
    [CHANNEL]: CHANNEL_VALUE,
    [CHANNEL_TYPE]: CHANNEL_TYPE_VALUE,
  };

  if (req) {
    headers.cookie = req.headers.cookie;
    headers[LOCALE] = getCookieValue(req.headers.cookie, DC3_LOCALE);
    headers[GRAPHQL_TOKEN_KEY] = getCookieValue(
      req.headers.cookie,
      AUTH_TOKEN_KEY,
    );
  }

  if (query) {
    const debugHeader = getQueryValue(query, X_CLOUD_DEBUG);

    if (debugHeader) {
      headers[X_CLOUD_DEBUG] = debugHeader;
    }
  }

  headers[X_RID] = getShortId();

  return headers as GraphQLRequestHeaders;
};

export const getHeadersFromProps = ({ router }: { router: Router }) => {
  const { serverRuntimeConfig, publicRuntimeConfig } = getConfig();
  const { CHANNEL_VALUE, CHANNEL_TYPE_VALUE, TENANT, APP_NAME } =
    publicRuntimeConfig;
  const { ALEXIS_KEY } = serverRuntimeConfig;

  const headers: Partial<GraphQLRequestHeaders> = {
    [CORPORATE_IDENTITY]: TENANT || 'kramp',
    [X_DC3_APP_NAME]: APP_NAME || 'dc3-app-name',
    [CHANNEL]: CHANNEL_VALUE,
    [CHANNEL_TYPE]: CHANNEL_TYPE_VALUE,
    [X_API_KEY]: ALEXIS_KEY,
  };

  const { query } = router;
  const debugHeader = getQueryValue(query, X_CLOUD_DEBUG);

  if (debugHeader) {
    headers[X_CLOUD_DEBUG] = debugHeader;
  }
  headers[X_RID] = getShortId();
  return headers as GraphQLRequestHeaders;
};
