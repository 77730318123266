import * as Types from '../../../../../data-access/graphql/src/lib/generated-types/gql.model';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type GQLLogIntentMutationVariables = Types.Exact<{
  intent: Types.GQLIntent;
}>;


export type GQLLogIntentMutation = { __typename?: 'Mutation', log_intent: boolean };


export const LogIntentDocument = gql`
    mutation LogIntent($intent: Intent!) {
  log_intent(intent: $intent)
}
    `;
export type GQLLogIntentMutationFn = Apollo.MutationFunction<GQLLogIntentMutation, GQLLogIntentMutationVariables>;

/**
 * __useLogIntentMutation__
 *
 * To run a mutation, you first call `useLogIntentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogIntentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logIntentMutation, { data, loading, error }] = useLogIntentMutation({
 *   variables: {
 *      intent: // value for 'intent'
 *   },
 * });
 */
export function useLogIntentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GQLLogIntentMutation, GQLLogIntentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<GQLLogIntentMutation, GQLLogIntentMutationVariables>(LogIntentDocument, options);
      }
export type LogIntentMutationHookResult = ReturnType<typeof useLogIntentMutation>;
export type LogIntentMutationResult = Apollo.MutationResult<GQLLogIntentMutation>;
export type LogIntentMutationOptions = Apollo.BaseMutationOptions<GQLLogIntentMutation, GQLLogIntentMutationVariables>;