/***
 * Use together with 'react-hook-form' package.
 * This component wraps the default Switch component inside
 * a Controller component from react-hook-form.
 *  */

import React from 'react';
import {
  Control,
  Controller,
  FieldErrors,
  FieldValues,
  FieldError,
  FieldPath,
  UseControllerProps,
} from 'react-hook-form';
import { Switch, SwitchProps } from '../switch/Switch';
import { Box } from '@dc3/ui/box';
import { Message } from '@dc3/ui/message';

export interface SwitchControlledProps<T> extends SwitchProps {
  name: FieldPath<T>;
  control: Control<T>;
  errors: FieldErrors<T>;
  rules?: UseControllerProps<T>['rules'];
}

export const SwitchControlled = <T extends FieldValues>({
  name,
  control,
  errors,
  rules = {},
  ...fieldProps
}: SwitchControlledProps<T>) => {
  const validationError = errors[name] as FieldError;

  return (
    // Compared to other controlled fields, a Switch needs some extra space at the bottom.
    <Box marginBottom={1}>
      <Controller
        name={name}
        rules={rules}
        control={control}
        render={({ field: { onChange, value, ref } }) => (
          <Switch
            onChange={() => {
              onChange(!value);
            }}
            checked={value}
            inputRef={ref}
            {...fieldProps}
          />
        )}
      />
      {Boolean(validationError) && (
        <Message type="error" variant="outlined">
          {validationError.message}
        </Message>
      )}
    </Box>
  );
};
