import { AppContext } from 'next/app';
import { loadSecrets } from '@dc3/utils/secrets';
import { authenticationMiddleware } from './authentication.middleware';
import { getPermissionsMiddleware } from './permisions.middleware';
import { jwtExpiryMiddlewareClient } from './jwt-expiration.middleware';
import { userDataMiddleware } from './user-data.middleware';

export const getInitialAppProps = async ({ Component, ctx }: AppContext) => {
  let pageProps: Record<string, unknown> = {};

  // Only execute server side
  if (ctx.req) {
    try {
      const data = await loadSecrets();
      pageProps.secrets = data?.public;
    } catch (e) {
      console.log('Failed loading secrets', e);
    }
  }

  if (Component.getInitialProps) {
    pageProps = (await Component.getInitialProps(ctx)) || {};
  }

  const { idToken, refreshToken } = await jwtExpiryMiddlewareClient(ctx);

  // check if the user is logged in
  authenticationMiddleware(ctx);

  // decode the jwt token and get the preselected country if available.
  pageProps.userData = userDataMiddleware(ctx);

  pageProps.permissions = await getPermissionsMiddleware(ctx, {
    idToken,
    refreshToken,
  });

  return {
    pageProps,
  };
};
