import getConfig from 'next/config';

export const PRODUCTION = process.env.NODE_ENV === 'production';
export const DEVELOPMENT = !PRODUCTION;

export const getEnvironmentVariable = (key: string | string[]) => {
  const { publicRuntimeConfig } = getConfig();

  if (typeof key === 'string') {
    return publicRuntimeConfig[key];
  }

  return key.map((item) => publicRuntimeConfig[item]);
};
