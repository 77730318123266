export const sentryIgnoreList = [
  'ApolloError',
  'QUOTATION_FREIGHT_CHARGE_NOT_FOUND',
  'PRICELIST_NOT_FOUND',
  'user with provided logon id already exists',
  /Account \S+ not found/,
  'The user is not authorized to perform the action',
  '[Unauthenticated Access] Please login again!',
  /does not exist or is not visible to the caller/,
  /No value available for key/,
];
