/* eslint-disable @typescript-eslint/no-var-requires */
import React, { memo, useMemo } from 'react';
import { useIntl } from 'react-intl';
import isEqual from 'react-fast-compare';
import countries from 'i18n-iso-countries';
import { useI18n } from '@dc3/utils/hooks';
import { Autocomplete } from '../autocomplete/Autocomplete';
import { Input } from '../input/Input';

countries.registerLocale(require('i18n-iso-countries/langs/en.json'));
countries.registerLocale(require('i18n-iso-countries/langs/nl.json'));

type Option = Record<string, string>;

interface CountriesDropdownProps {
  value?: string;
  disabled?: boolean;
  onChange: (option: Option) => void;
  id?: string;
}

const CountriesDropdownComponent: React.VFC<CountriesDropdownProps> = ({
  id = 'countries-dropdown',
  value,
  disabled,
  onChange,
  ...rest
}) => {
  const translate = useI18n();
  const { locale } = useIntl();

  const options = useMemo(() => {
    const list = countries.getNames(locale, { select: 'official' });

    return Object.keys(list).map((item) => ({
      value: item,
      label: list[item],
    }));
  }, [locale]);

  const initialValue = useMemo(
    () => ({
      label: countries.getName(value as string, locale, { select: 'official' }),
      value,
    }),
    [locale, value],
  );

  return (
    // Todo: make this a Select instead of autocomplete
    <Autocomplete
      id={id}
      value={initialValue ?? {}}
      disabled={disabled}
      disableClearable
      options={options}
      onChange={(_, option) => onChange(option as Option)}
      getOptionLabel={(option) => (option as Option).label}
      isOptionEqualToValue={(option: Option, currentValue: Option) =>
        (option.value as string) === (currentValue.value as string)
      }
      renderInput={(params) => (
        <Input label={translate('global.country')} {...params} />
      )}
      {...rest}
    />
  );
};

export const CountriesDropdown = memo(CountriesDropdownComponent, isEqual);
