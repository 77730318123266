import { createContext } from 'react';

export interface AccessControlRoles {
  permissions: string[];
  tenant: string;
}

export const AccessControlContext = createContext<AccessControlRoles>({
  permissions: [],
  tenant: '',
});
