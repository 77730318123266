import React from 'react';
import MaterialTooltip from '@mui/material/Tooltip';
import { ThemeProps } from '@dc3/utils/theme';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

const tooltipCSS = ({ theme }: ThemeProps) => css`
  background-color: ${theme.palette.primary.main};
`;

export const StyledTooltip = styled(({ className, ...other }: any) => (
  <MaterialTooltip classes={{ tooltip: className }} {...other} />
))`
  ${tooltipCSS}
`;
