import * as React from 'react';
import { Country } from '@dc3/utils/tenant-config';
import { UserData } from './user-data.middleware';
import { Brand } from '@dc3/utils/theme';

export const UserDataContext = React.createContext<{
  userData?: UserData;
  country?: Country;
  viewMode?: Brand;
  isAuthenticated?: boolean;
  setViewMode: React.Dispatch<React.SetStateAction<Brand>>;
  setCountry: React.Dispatch<React.SetStateAction<Country>>;
}>({
  userData: undefined,
  country: undefined,
  viewMode: undefined,
  isAuthenticated: false,
  setViewMode: () => {},
  setCountry: () => {},
});
