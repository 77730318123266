import React, { useEffect, useState } from 'react';
import { Loading } from './Loading';
import { StyledLoadingOverlay } from './LoadingOverlay.styles';
import { LoadingWrapper } from './LoadingOverlay.styles';

interface LoadingOverlayProps {
  isLoading?: boolean;
  progress?: number;
  message?: string;
  height?: string | number;
  width?: string | number;
  children?: React.ReactNode;
  sx?: object;
}

export const LoadingOverlay = ({
  message,
  isLoading,
  progress,
  height = '100%',
  width = 'auto',
  children,
  sx,
}: LoadingOverlayProps) => {
  const [showProgressOverlay, setShowProgressOverlay] = useState(false);

  useEffect(() => {
    if (progress > 0 && progress < 100) {
      setShowProgressOverlay(true);
    }

    // Once completed close loading overlay after a small delay
    if (progress === 100) {
      setTimeout(() => {
        setShowProgressOverlay(false);
      }, 500);
    }
  }, [progress]);

  return (
    <LoadingWrapper style={{ height, width, ...sx }}>
      {(isLoading || showProgressOverlay) && (
        <StyledLoadingOverlay id="loading-overlay">
          <Loading message={message} progress={progress} />
        </StyledLoadingOverlay>
      )}

      {children}
    </LoadingWrapper>
  );
};

export { LoadingWrapper };
