import { alpha } from '@mui/material';
import { AppColors, CustomPalette } from './brand.model';

// don't export this.
const dc3Colors = {
  primaryLight: '#67AABD',
  primary: '#037083',
  primaryDark: '#014153',

  //teal
  secondaryLight: '#9fd2d5',
  secondary: '#3fa4ab',
  secondaryDark: '#328389',

  white: '#FFFFFF',
  whitesmoke: '#F9F9F9',
  primaryLighter: '#f1f7f8',

  errorBackground: '#FDEDED',
  warningBackground: '#fdf8ed',
};

// colors used by all brands
const appColors: AppColors = {
  krampRed: '#ae0000',
  sidebar: '#fafafa',
  bgLogin: '#fafafa',
  topBarHome: dc3Colors.white,
  topBar: dc3Colors.primaryDark,
  contentBackground: {
    subtle: dc3Colors.whitesmoke,
    default: dc3Colors.whitesmoke,
    primary: dc3Colors.primaryLighter,
    error: dc3Colors.errorBackground,
    warning: dc3Colors.warningBackground,
  },
  loadingOverlay: alpha(dc3Colors.white, 0.3),
};

export const colors: CustomPalette = {
  appColors,
  palette: {
    mode: 'light',
    background: {
      default: dc3Colors.whitesmoke,
      paper: dc3Colors.white,
    },
    primary: {
      light: dc3Colors.primaryLight,
      main: dc3Colors.primary,
      dark: dc3Colors.primaryDark,
    },
    secondary: {
      light: dc3Colors.secondaryLight,
      main: dc3Colors.secondary,
      dark: dc3Colors.secondaryDark,
      contrastText: dc3Colors.white,
    },
    info: {
      light: dc3Colors.secondaryLight,
      main: dc3Colors.secondary,
      dark: dc3Colors.secondaryDark,
    },
  },
};
