import { useEffect, useState } from 'react';

export const useWindowSize = () => {
  const [width, setWidth] = useState<number>();
  const [height, setHeight] = useState<number>();

  const listener = () => {
    setWidth(window?.innerWidth);
    setHeight(window?.innerHeight);
  };

  useEffect(() => {
    if (!window) {
      return;
    }
    window?.addEventListener('resize', listener);
    return () => {
      window?.removeEventListener('resize', listener);
    };
  }, []);

  return {
    width,
    height,
  };
};
