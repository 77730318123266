import React, { useCallback } from 'react';
import { IntlShape, useIntl } from 'react-intl';

export const useI18n = (base = '', overrideReturnErrors = false) => {
  const { formatMessage, messages } = useIntl();
  const messageBase = base ? `${base}.` : '';

  return useCallback(
    (
      value?: string,
      values?: Record<string, string | number | React.ReactNode>,
      opts?: Parameters<IntlShape['formatMessage']>[2],
    ) => {
      const id = isGlobal(value) ? value : `${messageBase}${value}`;
      if (!id) return '';

      if (overrideReturnErrors && !messages[id]) {
        return id;
      }

      return formatMessage({ id }, { ...values, ...boldSupport }, opts);
    },
    [formatMessage, messageBase, messages, overrideReturnErrors],
  );
};

const isGlobal = (value?: string) => value?.startsWith('global.');

export const boldSupport = {
  // eslint-disable-next-line react/display-name
  strong: (children: React.ReactNode) => <strong>{children}</strong>,
} as any;

// Need to export separately due to https://github.com/microsoft/TypeScript/issues/28481
export type UseI18nResponse = ReturnType<typeof useI18n>;
