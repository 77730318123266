import { NextPageContext } from 'next';
import { parseCookies, setCookie, destroyCookie } from 'nookies';
import { isJwtExpired, refreshIdToken } from './refresh-token';
import { AUTH_REFRESH_TOKEN_KEY, AUTH_TOKEN_KEY } from '@dc3/utils/constants';
import { PRODUCTION } from '@dc3/utils/constants';

const COOKIE_OPTIONS = {
  path: '/',
  httpOnly: true,
  secure: PRODUCTION,
};

interface Tokens {
  idToken: string;
  refreshToken: string;
}

export const jwtExpiryMiddlewareClient = async (
  ctx: NextPageContext,
): Promise<Tokens> => {
  const { idToken: idTokenFromCookies, refreshToken: refreshTokenFromCookies } =
    parseCookies(ctx) || {};

  let identificationToken = idTokenFromCookies;
  let refreshIdentificationToken = refreshTokenFromCookies;

  if (idTokenFromCookies && refreshTokenFromCookies) {
    if (isJwtExpired(idTokenFromCookies)) {
      const { errors, idToken, refreshToken } = await refreshIdToken(
        refreshTokenFromCookies,
      );

      identificationToken = idToken;
      refreshIdentificationToken = refreshToken;

      if (!errors) {
        setCookie(ctx, AUTH_TOKEN_KEY, idToken, COOKIE_OPTIONS);
        setCookie(ctx, AUTH_REFRESH_TOKEN_KEY, refreshToken, COOKIE_OPTIONS);
      } else {
        destroyCookie(ctx, AUTH_TOKEN_KEY, COOKIE_OPTIONS);
        destroyCookie(ctx, AUTH_REFRESH_TOKEN_KEY, COOKIE_OPTIONS);
      }
    }
  }

  return {
    idToken: identificationToken,
    refreshToken: refreshIdentificationToken,
  };
};
