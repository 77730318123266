type WeekDayLength = 'narrow' | 'short' | 'long';

const weekdayDateMap: Record<string, Date> = {
  Mon: new Date('2020-01-06'),
  Tue: new Date('2020-01-07'),
  Wed: new Date('2020-01-08'),
  Thu: new Date('2020-01-09'),
  Fri: new Date('2020-01-10'),
  Sat: new Date('2020-01-11'),
  Sun: new Date('2020-01-12'),
};

const shortWeekdays = Object.keys(weekdayDateMap);

export const getDayOfWeek = (
  shortName: string,
  locale = 'en-US',
  length: WeekDayLength = 'long',
) =>
  new Intl.DateTimeFormat(locale, { weekday: length }).format(
    weekdayDateMap[shortName],
  );

export const getDaysOfWeek = (
  locale = 'en-US',
  length: WeekDayLength = 'long',
) => shortWeekdays.map((shortName) => getDayOfWeek(shortName, locale, length));
