import { useRouter } from 'next/compat/router';
import { useCallback, useState } from 'react';

const useQueryParameter = () => {
  const router = useRouter();
  // Return query params
  return router?.query;
};

const useClearQueryParams = (initialState = false): [boolean, any] => {
  const router = useRouter();
  const [state, setState] = useState<boolean>(initialState);

  const clearRouter = useCallback((val: boolean): void => {
    if (router && val) {
      const queryParam = router?.query;
      if (router?.isReady && Object.keys(queryParam).length !== 0) {
        // This will prevent your router from push when the clients still's rendering
        if (typeof window === 'undefined') setState(false);

        // Replace params so the modal will not open always
        router?.push(router?.pathname, undefined, { shallow: true });

        setState(true);
      }
    }
  }, []);

  return [state, clearRouter];
};

export { useQueryParameter, useClearQueryParams };
