import React from 'react';
import { css, Global } from '@emotion/react';
import { globalCSS, resetCSS, useTheme } from '@dc3/utils/theme';
import { coreCSS } from '../../styles/dc3.styles';

export const LoginLayout = ({ children }: { children?: React.ReactNode }) => {
  const theme = useTheme(); // if your theme is provided via context

  return (
    <>
      <Global styles={css([resetCSS, globalCSS, coreCSS(theme)])} />
      {children}
    </>
  );
};
