export const GRAPHQL_TOKEN_KEY = 'x-auth-jwt';
export const AUTH_TEMP_TOKEN = 'x-temp-jwt';
export const CORPORATE_IDENTITY = 'ctx-corporate-identity';
export const LOCALE = 'ctx-locale';
export const ACCOUNT_ID = 'ctx-account-id';
export const ORGANIZATION_ID = 'ctx-organization-id';
export const X_CLOUD_DEBUG = 'x-cloud-debug';
export const X_RID = 'x-rid';
export const X_API_KEY = 'x-api-key';
export const CHANNEL = 'ctx-channel';
export const CHANNEL_TYPE = 'ctx-channel-type';
export const X_ENABLE_MOCK_MODE = 'x_enable_mock_mode';
export const X_DC3_APP_NAME = 'x-dc3-app-name';
export const X_FEATURES = 'x-features';
// sentry-trace and baggage are headers used by sentry for tracing
export const SENTRY_TRACE = 'sentry-trace';
export const SENTRY_BAGGAGE = 'baggage';
