import styled from '@emotion/styled';

export const LoadingWrapper = styled.div`
  position: relative;
  transition: 0.4s all;
  min-height: 100px;
`;

export const StyledLoadingOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: ${({ theme }) => theme.zIndex.appBar + 1};
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.appColors.loadingOverlay};
  color: white;
  opacity: 1;
  margin-bottom: -150px;
`;
