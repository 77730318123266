import * as Types from '../../../../../data-access/graphql/src/lib/generated-types/gql.model';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type GQLGetPermissionsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GQLGetPermissionsQuery = { __typename?: 'Query', permissions: Array<{ __typename?: 'PermissionsInOrganization', permissions: Array<string>, organization: { __typename?: 'Organization', id: string, name: string } }> };


export const GetPermissionsDocument = gql`
    query getPermissions {
  permissions {
    organization {
      id
      name
    }
    permissions
  }
}
    `;

/**
 * __useGetPermissionsQuery__
 *
 * To run a query within a React component, call `useGetPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPermissionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPermissionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GQLGetPermissionsQuery, GQLGetPermissionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GQLGetPermissionsQuery, GQLGetPermissionsQueryVariables>(GetPermissionsDocument, options);
      }
export function useGetPermissionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GQLGetPermissionsQuery, GQLGetPermissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GQLGetPermissionsQuery, GQLGetPermissionsQueryVariables>(GetPermissionsDocument, options);
        }
export type GetPermissionsQueryHookResult = ReturnType<typeof useGetPermissionsQuery>;
export type GetPermissionsLazyQueryHookResult = ReturnType<typeof useGetPermissionsLazyQuery>;
export type GetPermissionsQueryResult = Apollo.QueryResult<GQLGetPermissionsQuery, GQLGetPermissionsQueryVariables>;