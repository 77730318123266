import { LOCALE } from '@dc3/utils/constants';
import { getFormattedLanguageCode } from './countries';

type DisplayLocaleTypes = 'language' | 'region' | 'script' | 'currency';

interface DisplayLocaleNameParams {
  /* the language to show the display name of the code property in */
  displayLanguage: string;
  /* The country or language code to show the display name of */
  code: string;
  type?: DisplayLocaleTypes;
}

export const displayLocaleName = ({
  displayLanguage,
  code,
  type = 'region',
}: DisplayLocaleNameParams) => {
  try {
    // typescript still dont have the types for `DisplayNames`
    // eslint-disable-next-line
    const displayName = new (Intl as any).DisplayNames([displayLanguage], {
      type,
    });

    if (type === 'language') {
      const languageCode = getFormattedLanguageCode(code);
      return languageCode ? displayName.of(languageCode) : code;
    }

    return displayName.of(code);
  } catch (e) {
    return code?.toUpperCase();
  }
};

export const getLocaleHeader = (languageCode: string) => ({
  headers: { [LOCALE]: languageCode },
});
