import { NextApiRequest, NextApiResponse } from 'next';
import { getAppVersion } from './get-app-version';

export const healthcheck = (_: NextApiRequest, res: NextApiResponse) => {
  const check = {
    uptime: process.uptime(),
    message: 'OK',
    timestamp: Date.now(),
    version: getAppVersion(),
  };
  try {
    res.send(check);
  } catch (e: any) {
    check.message = e;
    res.status(503).send(check);
  }
};
