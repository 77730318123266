import { CookieSerializeOptions, serialize } from 'cookie';
import { NextApiResponse } from 'next';

export const setNextResponseCookies = (
  res: NextApiResponse,
  cookies: {
    name: string;
    value: string;
  }[],
  options: CookieSerializeOptions = {},
) => {
  if (options.maxAge) {
    options.expires = new Date(Date.now() + options.maxAge);
    options.maxAge /= 1000;
  }

  cookies.forEach(({ name, value }) => {
    // Serialize the cookie to a string.
    const serializedCookie = serialize(name, String(value), options);

    // Properly set multiple Set-Cookie headers.
    res.setHeader('Set-Cookie', [
      ...((res.getHeader('Set-Cookie') as string[]) || []), // Preserve previously set cookies
      serializedCookie, // Add the new cookie
    ]);
  });
};

export const getCookieValue = (
  cookies: string | undefined,
  name: string,
): string | undefined =>
  (cookies?.split(';').find((s) => s.includes(name + '=')) || '')
    .split('=')
    .slice(1)
    .join('=');
