import React from 'react';
import { NotificationTypes } from '@dc3/ui/notification';
import Alert from '@mui/material/Alert';

interface MessageProps {
  type?: NotificationTypes;
  variant?: 'filled' | 'outlined' | 'standard';
  children?: React.ReactNode;
  sx?: object;
}
export const Message = ({
  type,
  children,
  variant = 'filled',
  sx,
}: MessageProps) => (
  <Alert variant={variant} severity={type} sx={sx}>
    {children}
  </Alert>
);
