import { InputAdornment, IconButton, Icon } from '@mui/material';
import React, { useState } from 'react';
import { Input } from '@dc3/ui/form-fields';

export const PasswordField = (props) => {
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);

  return (
    <Input
      type={showPassword ? 'text' : 'password'}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
            >
              {showPassword ? (
                <Icon>visibility</Icon>
              ) : (
                <Icon>visibility_off</Icon>
              )}
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...props}
    />
  );
};
