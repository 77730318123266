import { ThemeProps } from '@dc3/utils/theme';
import styled from '@emotion/styled';
import MaterialIconButton from '@mui/material/IconButton';
import { css } from '@emotion/react';
import { Button } from '@mui/material';

interface FlagButtonProps extends ThemeProps {
  hasError?: boolean;
}

const flagButtonHover = css`
  &:hover {
    /* a darker background is needed to be able to see the effect on the dark primary color */
    background: rgba(0, 0, 0, 0.2);
  }
`;

const flagButtonCSS = ({ theme, hasError }: FlagButtonProps) => css`
  /* fixes emoji color bug */
  color: ${hasError ? 'white' : 'inherit'};
  background-color: ${hasError && theme.palette.error.main};
  /* perfect circle */
  width: 40px;
  height: 40px;
`;

export const FlagButton = styled(MaterialIconButton, {
  // prevents DOM error unknown prop error
  shouldForwardProp: (prop) => prop !== 'hasError',
})`
  ${flagButtonCSS};
  ${flagButtonHover};
`;

export const StyledButton = styled(Button)`
  color: white;
  border: 1px solid red;
  background: rgba(0, 0, 0, 0.4);
  &:hover {
    background: rgba(0, 0, 0, 0.5);
  }
`;
