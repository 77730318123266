export const dimensions = {
  sidebarWidth: '220px',
  sidebarCollapsedWidth: '60px',
  sidebarTop: '16px',
  sidebarCollapseButtonHeight: '24px',
  heightAppBar: '64px',
  heightSearchList: '360px',
  actionsHeight: '2.5rem',
  treeNodeIndentationWidth: '15px',
  treeNodeHeight: '32px',
};
