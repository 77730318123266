import React, { useEffect, useState } from 'react';
import { DynamicFieldInput } from './DynamicFieldInput';
import { Box } from '@dc3/ui/box';
import { Button } from '@dc3/ui/buttons';
import { Select } from '../select/Select';
import { useDebounce, useI18n } from '@dc3/utils/hooks';
import { Icon } from '@dc3/ui/icon';
import { Grid } from '@dc3/ui/grid';

import { DynamicInputStylesInterface } from './DynamicFieldInput.styles';
import { Divider } from '@mui/material';
export { Fieldset } from './DynamicForm.styles';

export interface DynamicFieldInterface {
  key: string;
  value: string;
}

interface DynamicFormProps {
  goalsList: string[];
  fields?: DynamicFieldInterface[];
  goalsAdded: (goals: DynamicFieldInterface[]) => void;
  goalDeleted: (key: string) => void;
  canEdit?: boolean;
}

export const DynamicForm = ({
  goalsList,
  fields,
  goalsAdded,
  goalDeleted,
  canEdit,
}: DynamicFormProps) => {
  const translate = useI18n();

  const [fieldsState, setfieldsState] = useState<DynamicFieldInterface[]>(
    fields || [],
  );
  const [activeGoal, setActiveGoal] = useState<string>(goalsList[0]);
  const [goalValue, setGoalValue] = useState<string>('');
  const [availableGoals, setAvailableGoals] = useState<string[]>([]);
  const debouncedGoalValue = useDebounce(goalValue);
  const dynamicInputStylesInterface: DynamicInputStylesInterface = {
    min_width: '80%',
  };

  useEffect(() => {
    const goals = getAvailableGoals();
    setfieldsState(fields || []);
    setAvailableGoals(goals);
    //getAvailableGoals will trigger to many rerenders
    //eslint-disable-next-line
  }, [fields, setfieldsState, setAvailableGoals]);

  const getAvailableGoals = () =>
    goalsList.filter((item) => !fieldsState.map((f) => f.key).includes(item));

  const addField = () => {
    if (
      !fieldsState.find((goal) => goal.key === activeGoal) &&
      activeGoal !== ''
    ) {
      setfieldsState([...fieldsState, { key: activeGoal, value: '' }]);
      setActiveGoal('');
    }
  };

  const removeField = (key: string) => {
    goalDeleted(key);
    setfieldsState(fieldsState.filter((f) => f.key !== key));
  };

  useEffect(() => {
    if (debouncedGoalValue) {
      goalsAdded(fieldsState.map((f) => ({ key: f.key, value: f.value })));
    }
    // only trigger on debounced Goal value.
    //eslint-disable-next-line
  }, [debouncedGoalValue]);

  const handleFieldChange = (key: string, value: string) => {
    const updatedFields = fieldsState.map((f) => {
      const field = { ...f };
      if (field.key === key) {
        field.value = value;
      }
      return field;
    });
    setGoalValue(value);
    setfieldsState(updatedFields);
  };

  const fieldsAreValid = !fieldsState.some((f) => f.value.length < 1);
  const goalIsValid = goalsList.includes(activeGoal);
  const enableAddField = fieldsAreValid && goalIsValid;

  return (
    <Box>
      <Grid container>
        {fieldsState.map((f, index) => (
          <Grid item key={f.key} xs={12}>
            <DynamicFieldInput
              index={index}
              field={f}
              handleFieldChange={handleFieldChange}
              removeField={removeField}
              dynamicStyle={dynamicInputStylesInterface}
            />
          </Grid>
        ))}
      </Grid>

      {fieldsState?.length > 0 && <Divider sx={{ mb: 2.5 }} />}

      {availableGoals.length > 0 && (
        <Box
          minWidth="50%"
          maxWidth={480}
          display="flex"
          alignItems="flex-start"
        >
          <Box display={'flex'} alignItems={'center'} flexGrow={1}>
            <Box marginRight={2} flex={1}>
              <Select
                size="small"
                label={translate('campaigns.goal')}
                value={activeGoal}
                options={availableGoals.map((goal) => ({
                  label: goal,
                  value: goal,
                }))}
                required
                onChange={(e) => setActiveGoal(e.target.value as string)}
              />
            </Box>
            <Button
              disabled={!canEdit && !enableAddField}
              onClick={addField}
              startIcon={<Icon>add</Icon>}
              sx={{
                padding: '4px 10px',
                height: '32px',
                fontSize: '14px',
              }}
            >
              Add Goal
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};
