import * as remoteConfig from './remote.json';

type Countries = (typeof remoteConfig)['defaultLocalePerCountry'];
type LocalesPerCountry = (typeof remoteConfig)['buyerToSellerLocale'];
type DefaultLocalePerLanguage =
  (typeof remoteConfig)['defaultLocalePerLanguage'];

export const getCountries = (defaultLocalePerCountry: Countries) =>
  Object.keys(defaultLocalePerCountry).filter(
    (country) => country !== 'DEFAULT',
  );

export const getLanguagesPerCountry = (
  localesPerCountry: LocalesPerCountry,
): Record<string, string[]> =>
  Object.keys(localesPerCountry).reduce<Record<string, string[]>>(
    (acc, key) => {
      const country = key as keyof LocalesPerCountry;
      const locales = localesPerCountry[country];
      acc[country] = locales.map((locale) => locale.split('_')[0]);
      return acc;
    },
    {},
  );

// These languages from the defaultLocalePerLanguage are not supported/selectable on the kramp.com webshop
const unsupportedLanguages = ['bg', 'et', 'lt', 'lv'];

export const getWebshopLanguages = (
  defaultLocalePerLanguage: DefaultLocalePerLanguage,
) =>
  Object.keys(defaultLocalePerLanguage).filter(
    (lang) => !unsupportedLanguages.includes(lang),
  );
