import { FeatureValue } from '@dc3/utils/tenant-config';

export const isFeatureVisible = (
  feature: FeatureValue,
  env: string,
  countryCode: string,
  userRoles?: number[],
) => {
  if (!feature) return false;
  if (typeof feature === 'boolean') return feature;

  const showFeature = feature.reduce(
    (result, { environments, countries, roles }) => {
      // If we already have a match we don't need to check other entries
      if (result) return true;

      const isEnvMatch = environments.includes(env.toLowerCase());
      const isCountriesMatch = countries
        ? countries.includes(countryCode)
        : true;

      const isRolesMatch = roles
        ? roles.some((role) => userRoles?.includes(role))
        : true;

      return isEnvMatch && isCountriesMatch && isRolesMatch;
    },
    false,
  );

  return showFeature;
};
