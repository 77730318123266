import { useState } from 'react';
import { debounce } from '@dc3/utils/helpers';

export interface GridOrder {
  by: string;
  direction: string;
}

export interface GridFilter {
  field: string;
  value: string | string;
}

export interface GridEvent {
  sort: Array<{
    field: string;
    dir: string;
  }>;
  filter: {
    filters: Array<{
      field: string;
      value: string;
    }>;
  };
}

export interface GridControlSetter {
  order: GridOrder | null;
  filter: GridFilter[] | null;
}

export type GridControls = [(event: GridEvent) => void, GridControlSetter];

export const useGridControls = (): GridControls => {
  const [order, setOrder] = useState<GridOrder | null>(null);
  const [filter, setFilter] = useState<GridFilter[] | null>(null);

  const handleEvent = debounce((event: GridEvent): void => {
    if (event.sort) {
      const sort = event.sort[0];

      setOrder({
        by: sort?.field || 'none',
        direction: sort?.dir || 'desc',
      });
    } else {
      setOrder({
        by: 'none',
        direction: 'desc',
      });
    }

    if (event.filter) {
      const filters = event.filter.filters.map((item) => ({
        field: item.field,
        value: item.value,
      }));

      setFilter(filters);
    } else {
      setFilter(null);
    }
  });

  return [handleEvent, { order, filter }];
};
