import {
  getCountries,
  getLanguagesPerCountry,
  getWebshopLanguages,
} from './config.utils';
import remoteConfig from './remote.json';

export * from './types';

/**
 * INSTRUCTIONS
 *
 * You can supply different configurations for 'pages' and 'features' props.
 * You can enable or disable a feature based on:
 *
 *   - Boolean values
 ------------------------------------
 feature: true,
 ------------------------------------

 *   - Certain environments, f.i. only enable a feature on DEV and STG. (For all countries):
 ------------------------------------
 feature:[{
          environments: ['dev', 'stg'],
        }],
 ------------------------------------

 *   - Environments and countries, f.i. only enable a feature on DEV and STG for 'NL' and 'DE' and to particular roles:
 ------------------------------------
 feature:[{
          environments: ['dev', 'stg'],
          countries: ['NL', 'DE'],
          roles:[83, 84],
        }]
 ------------------------------------

 *   - Different countries per environment, f.i. enable a feature on DEV and STG for 'NL' and 'DE' but on PRD only enable it for NL and to particular roles :
 ------------------------------------
 feature:[{
          environments: ['dev', 'stg'],
          countries: ['NL', 'DE'],
        },
 {
          environments: ["prd"],
          countries: ["NL"],
          roles:[83, 84],
        }]
 ------------------------------------
 */

export const UserRoleConfig = [
  {
    environments: ['dev', 'stg'],
    id: 6,
  },
  {
    environments: ['prd'],
    id: 1,
  },
];

export const proposalExpiryOptions = {
  SE: {
    default: 30,
    other: [1, 7, 14],
  },
  DEFAULT: {
    default: 14,
    other: [1, 7, 30],
  },
};

const quotationConfiguration = {
  proposalExpiryOptions,
};

export default {
  pages: {
    '/': [
      {
        environments: ['dev', 'stg', 'prd'],
      },
    ],
    '/authentication': [
      {
        environments: ['dev', 'stg', 'prd'],
      },
    ],
    '/dashboard': [
      {
        environments: ['dev'],
      },
    ],
    '/campaign': [
      {
        environments: ['dev', 'stg', 'prd'],
      },
    ],
    '/search': [
      {
        environments: ['dev', 'stg', 'prd'],
      },
    ],
    '/organization': [
      {
        environments: ['dev', 'stg', 'prd'],
      },
    ],
    '/quotation': [
      {
        environments: ['dev', 'stg', 'prd'],
        countries: [
          'FR',
          'DK',
          'ES',
          'FI',
          'RO',
          'SK',
          'DE',
          'HU',
          'CZ',
          'NL',
          'AT',
          'CH',
          'GB',
          'IT',
          'NO',
          'PL',
          'SE',
          'BE',
          'SI',
          'HR',
          'IE',
        ],
      },
    ],
    '/settings': [
      {
        environments: ['dev', 'stg', 'prd'],
      },
    ],
    '/delivery': [
      {
        environments: ['dev'],
      },
      {
        environments: ['stg'],
        roles: [97],
      },
      {
        environments: ['prd'],
        roles: [49],
      },
    ],
    '/alpha': [
      {
        environments: ['dev'],
      },
    ],
    '/role': [
      {
        environments: ['dev'],
      },
      {
        environments: ['stg'],
        //35 == TenantManager
        //37 == OrganizationManager
        roles: [35, 37],
      },
      {
        environments: ['prd'],
        //14 == TenantManager
        //15 == OrganizationManager
        roles: [14, 15],
      },
    ],
    '/hyperloop': [
      {
        environments: ['prd'],
        //38 == HyperloopUser
        //39 == HyperloopViewer
        roles: [38, 39],
      },
      {
        environments: ['stg'],
        roles: [85, 86],
      },
      {
        environments: ['dev'],
      },
    ],
    '/experimentation': [
      {
        environments: ['prd'],
        //40 == ExperimentUser
        //41 == ExperimentViewer
        roles: [40, 41],
      },
      {
        environments: ['stg'],
        //87 == ExperimentUser
        //88 == ExperimentViewer
        roles: [87, 88],
      },
      {
        environments: ['dev'],
        //34 == ExperimentUser
        //35 == ExperimentViewer
        roles: [34, 35],
      },
    ],
    '/feature-toggles': [
      {
        environments: ['dev'],
        roles: [43, 44],
      },
      {
        environments: ['stg'],
        roles: [94, 95],
      },
      //to be added in the future
      {
        environments: ['prd'],
        roles: [47, 46],
      },
    ],
  },
  features: {
    countrySelector: true,
    impersonation: true,
    qmPendingQuotations: [
      {
        environments: ['dev', 'stg'],
      },
    ],
    loginApp: [
      {
        environments: ['dev', 'stg', 'prd'],
      },
    ],
    catalogUploadAssets: [
      {
        environments: ['dev', 'stg', 'prd'],
      },
    ],
    qmCustomAddress: [
      {
        environments: ['dev'],
        countries: [
          'HU',
          'SK',
          'CZ',
          'RO',
          'CH',
          'AT',
          'GB',
          'DK',
          'FI',
          'NO',
          'SE',
          'IT',
          'FR',
          'ES',
          'PL',
          'BE',
          'SE',
          'NL',
          'DE',
          'SI',
          'HR',
        ],
      },
      {
        environments: ['stg'],
        countries: [
          'HU',
          'SK',
          'CZ',
          'RO',
          'CH',
          'AT',
          'GB',
          'DK',
          'FI',
          'NO',
          'SE',
          'IT',
          'FR',
          'ES',
          'PL',
          'BE',
          'SE',
          'NL',
          'DE',
          'SI',
          'HR',
        ],
      },
      {
        environments: ['prd'],
        countries: [
          'HU',
          'SK',
          'CZ',
          'RO',
          'CH',
          'AT',
          'GB',
          'DK',
          'FI',
          'NO',
          'SE',
          'IT',
          'FR',
          'ES',
          'PL',
          'BE',
          'SE',
          'NL',
          'DE',
          'SI',
          'HR',
        ],
      },
    ],
    qmCallOffOrders: [
      {
        environments: ['dev', 'stg'],
      },
      {
        environments: ['prd'],
        countries: ['NL'],
      },
    ],
    campaignExport: [
      {
        environments: ['dev', 'stg'],
      },
    ],
    findSearchResultSuppressions: [
      {
        environments: ['dev', 'stg', 'prd'],
      },
    ],
    quotationInvoiceAndComment: [
      {
        environments: ['dev', 'stg'],
        countries: [
          'FR',
          'ES',
          'RO',
          'SK',
          'DE',
          'HU',
          'CZ',
          'NL',
          'AT',
          'CH',
          'GB',
          'IT',
          'PL',
          'BE',
          'SI',
          'HR',
          'IE',
        ],
      },
      {
        environments: ['prd'],
        countries: [
          'FR',
          'ES',
          'RO',
          'SK',
          'DE',
          'HU',
          'CZ',
          'NL',
          'AT',
          'CH',
          'GB',
          'IT',
          'PL',
          'BE',
          'SI',
          'HR',
          'IE',
        ],
      },
    ],
    quotationPaymentTerms: [
      {
        environments: ['dev', 'stg'],
        countries: [
          'IT',
          'DK',
          'NO',
          'FI',
          'FR',
          'ES',
          'RO',
          'SK',
          'DE',
          'HU',
          'CZ',
          'NL',
          'AT',
          'GB',
          'PL',
          'BE',
          'SI',
          'HR',
          'IE',
        ],
      },
      {
        environments: ['prd'],
        countries: [
          'IT',
          'DK',
          'NO',
          'FI',
          'FR',
          'ES',
          'RO',
          'SK',
          'DE',
          'HU',
          'CZ',
          'NL',
          'AT',
          'GB',
          'PL',
          'BE',
          'SI',
          'HR',
          'IE',
        ],
      },
    ],
    qmConsolidateFullfilment: [
      {
        environments: ['dev', 'stg'],
      },
      {
        environments: ['prd'],
        countries: [
          'FR',
          'ES',
          'RO',
          'SK',
          'DE',
          'HU',
          'CZ',
          'NL',
          'AT',
          'CH',
          'GB',
          'IT',
          'PL',
          'BE',
          'SI',
          'HR',
          'DK',
          'NO',
          'FI',
          'SE',
          'IE',
        ],
      },
    ],
    qmInvoiceDelayed: [
      {
        environments: ['dev', 'stg'],
      },
      {
        environments: ['prd'],
        countries: [
          'FR',
          'ES',
          'RO',
          'SK',
          'DE',
          'HU',
          'CZ',
          'NL',
          'AT',
          'CH',
          'GB',
          'IT',
          'PL',
          'BE',
          'SI',
          'HR',
          'DK',
          'NO',
          'FI',
          'SE',
          'IE',
        ],
      },
    ],
    qmInvoiceSeparately: [
      {
        environments: ['dev', 'stg'],
        countries: [
          'FR',
          'ES',
          'RO',
          'SK',
          'DE',
          'HU',
          'CZ',
          'NL',
          'AT',
          'CH',
          'GB',
          'IT',
          'PL',
          'BE',
          'SI',
          'HR',
          'IE',
        ],
      },
      {
        environments: ['prd'],
        countries: [
          'FR',
          'ES',
          'RO',
          'SK',
          'DE',
          'HU',
          'CZ',
          'NL',
          'AT',
          'CH',
          'GB',
          'IT',
          'PL',
          'BE',
          'SI',
          'HR',
          'IE',
        ],
      },
    ],
    qmRequestShippingDate: [
      {
        environments: ['dev', 'stg'],
      },
      {
        environments: ['prd'],
        countries: [
          'FR',
          'ES',
          'RO',
          'SK',
          'DE',
          'HU',
          'CZ',
          'NL',
          'AT',
          'CH',
          'GB',
          'IT',
          'PL',
          'BE',
          'SI',
          'HR',
          'DK',
          'NO',
          'FI',
          'SE',
          'IE',
        ],
      },
    ],
    organizationPunchout: [
      {
        environments: ['dev', 'stg'],
      },
    ],
    qmFreightCharges: [
      {
        environments: ['dev'],
        countries: [
          'HU',
          'SK',
          'CZ',
          'RO',
          'CH',
          'AT',
          'GB',
          'IT',
          'FR',
          'ES',
          'PL',
          'BE',
          'SE',
          'NL',
          'FI',
          'IE',
          'DE',
          'SI',
          'HR',
          'NO',
          'DK',
          'NO',
        ],
      },
      {
        environments: ['stg'],
        countries: [
          'HU',
          'SK',
          'CZ',
          'RO',
          'CH',
          'AT',
          'GB',
          'IT',
          'FR',
          'ES',
          'PL',
          'BE',
          'SE',
          'NL',
          'FI',
          'IE',
          'DE',
          'SI',
          'HR',
          'NO',
          'DK',
          'NO',
        ],
      },
      {
        environments: ['prd'],
        countries: [
          'HU',
          'SK',
          'CZ',
          'RO',
          'CH',
          'AT',
          'GB',
          'IT',
          'FR',
          'ES',
          'PL',
          'BE',
          'SE',
          'NL',
          'FI',
          'IE',
          'NO',
          'DE',
          'SI',
          'HR',
          'DK',
        ],
      },
    ],
    qmFreightSurcharges: [
      {
        environments: ['dev'],
        countries: ['RO'],
      },
      {
        environments: ['stg'],
        countries: ['RO'],
      },
    ],
    qmManualFreightCharges: [
      {
        environments: ['dev'],
        countries: [
          'HU',
          'SK',
          'CZ',
          'RO',
          'CH',
          'AT',
          'GB',
          'IT',
          'FR',
          'ES',
          'PL',
          'BE',
          'SE',
          'NL',
          'FI',
          'IE',
          'DE',
          'SI',
          'HR',
          'NO',
          'DK',
          'NO',
        ],
      },
      {
        environments: ['stg'],
        countries: [
          'HU',
          'SK',
          'CZ',
          'RO',
          'CH',
          'AT',
          'GB',
          'IT',
          'FR',
          'ES',
          'PL',
          'BE',
          'SE',
          'NL',
          'FI',
          'IE',
          'DE',
          'SI',
          'HR',
          'NO',
          'DK',
          'NO',
        ],
      },
      {
        environments: ['prd'],
        countries: [
          'HU',
          'SK',
          'CZ',
          'RO',
          'CH',
          'AT',
          'GB',
          'IT',
          'FR',
          'ES',
          'PL',
          'BE',
          'SE',
          'NL',
          'FI',
          'IE',
          'NO',
          'DE',
          'SI',
          'HR',
          'DK',
        ],
      },
    ],
    qmCreateItem: [
      {
        environments: ['dev', 'stg'],
      },
      {
        environments: ['prd'],
        countries: ['NL', 'IT'],
      },
    ],
    qmFilterCountry: [
      {
        environments: ['dev', 'stg', 'prd'],
        countries: ['DE', 'IE', 'NL'],
      },
    ],
    qmVASItems: [
      {
        environments: ['dev', 'stg'],
      },
      {
        environments: ['prd'],
        countries: ['IT'],
      },
    ],
    qmSavedViews: [
      {
        environments: ['dev', 'stg'],
      },
    ],
    campaignPricePromotions: [
      {
        environments: ['dev', 'stg', 'prd'],
      },
    ],
    // move this to RBAC provider later
    orgDeleteAccount: [
      {
        environments: ['dev'],
      },
      {
        environments: ['stg'],
        roles: [35],
      },
      {
        environments: ['prd'],
        roles: [14],
      },
    ],
    // move this to RBAC provider later
    editToggles: [
      {
        environments: ['dev'],
        roles: [44],
      },
      {
        environments: ['stg'],
        roles: [95],
      },
      {
        environments: ['prd'],
        roles: [47],
      },
    ],
  },
  configuration: {
    draftCatalogId: null,
    webshopLanguages: getWebshopLanguages(
      remoteConfig.defaultLocalePerLanguage,
    ),
    countries: getCountries(remoteConfig.defaultLocalePerCountry),
    languagesPerCountry: getLanguagesPerCountry(
      remoteConfig.buyerToSellerLocale,
    ),
    organization: {
      propertiesOnSearch: [
        'customerPartnership',
        'canBuy',
        'corporateIdentity',
      ],
      propertiesOnQuotation: [
        'abcCode',
        'marketSegment',
        'marketSegmentCode',
        'customerSpecialistAccountId',
        'creditOnHold',
        'corporateIdentity',
        'customerNumber',
        'sellerCountry',
        'canBuy',
        'customerPartnership',
        'accountManagerAccountId',
        'corporateIdentityOrganization',
        'pricelist',
        'paymentModeDescription',
        'paymentTermDescription',
        'primaryContactId',
        'primaryContactName',
        'defaultLocale',
      ],
      propertiesIconMap: {
        abcCode: 'bubble_chart',
        customerSpecialistAccountId: 'support_agent',
        creditOnHold: 'money_off',
        corporateIdentity: 'corporate_fare',
        marketSegmentCode: 'pie_chart_outline',
        customerNumber: 'perm_identity',
        sellerCountry: 'flag',
        marketSegment: 'pie_chart',
        canBuy: 'shopping_cart',
        customerPartnership: 'group',
        accountManagerAccountId: 'admin_panel_settings',
        corporateIdentityOrganization: 'business',
        pricelist: 'sell',
        primaryContactId: 'contact_emergency',
        paymentModeDescription: 'sell',
        paymentTermDescription: 'sell',
        paymentMode: 'sell',
        paymentTermCode: 'sell',
        primaryContactName: 'person',
        defaultLocale: 'language',
      },
    },
    corporateIdentities: Object.keys(remoteConfig.ciToCompanyName),
    quotationConfiguration,
    UserRoleConfig,
  },
};
