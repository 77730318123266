import React from 'react';
import { IconProps as MaterialIconProps } from '@mui/material/Icon';
import { StyledIcon } from './Icon.styles';

interface IconProps extends MaterialIconProps {
  outlined?: boolean;
  customColor?: string;
}

export const Icon = ({ outlined = false, className, ...rest }: IconProps) => {
  const mergedClasses = outlined
    ? `${className} material-icons-outlined`
    : className;
  const mergedProps: MaterialIconProps = {
    ...rest,
    className: mergedClasses,
  };
  return <StyledIcon {...mergedProps} />;
};
