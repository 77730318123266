import { useContext } from 'react';
import { SnackBarContext } from './SnackBarContext';

/**
UseSnackBar Hook
Allows you to display a snackbar from wherever.

@example
const { setSnackBar } = useSnackBar();
setSnackBar({ type: 'success', text: 'Request Succeeded' });
*/
export const useSnackBar = () => {
  const { snack, setSnackBar } = useContext(SnackBarContext);
  return { snack, setSnackBar };
};
