import { useState, useEffect } from 'react';
import cloneDeep from 'lodash/cloneDeep';

//#Problem: Apollo hooks sets data to null while u make next call by lazy query
//(1)data(not undefined)--(2)loading_starts--|>(3)data(undefined)--(4)loading_ends--(5)|>data(not undefined)
//Coz of above mutation flow UI will become blank for sometime while loading even if we have data before

//#Solution: This hooks only set data when loading is not in progress so we skip step 3 of setting data to
//undefined while it is still loading. So in UI there is no glitch its just transforms from one data to another
//without becoming undefined in between
export const useLocalData = <T>(data: T, loading: boolean): T | undefined => {
  const [localData, setLocalData] = useState<T | undefined>();

  useEffect(() => {
    if (!loading) {
      setLocalData(cloneDeep<T>(data));
    }
    // eslint-disable-next-line
  }, [data, loading]);

  return localData;
};
