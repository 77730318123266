import { useUserData } from '@dc3/utils/auth';
import { useContext, useEffect, useMemo, useState } from 'react';
import { getEnvironmentVariable } from '@dc3/utils/constants';
import {
  Country,
  PageKeys,
  ParsedFeatures,
  TenantConfiguration,
} from '@dc3/utils/tenant-config';
import { isFeatureVisible } from './TenantConfiguration.utils';
import { TenantConfigurationContext } from './TenantConfigurationContext';
import mapValues from 'lodash/mapValues';

export const useConfiguration = () => {
  const {
    pages,
    configuration,
    features: featureConfiguration,
  } = useContext(TenantConfigurationContext) as TenantConfiguration;
  const [features, setFeatures] = useState<Partial<ParsedFeatures>>({});
  const { country, userData } = useUserData();
  const countryCode = country?.code || '';
  const env = getEnvironmentVariable('ENV') || 'dev';

  const userRoles = useMemo(
    () =>
      userData?.organizationRoles?.[userData?.organizationId]?.map((r) => +r),
    [userData],
  );

  // Handle pages
  const canViewPage = (pageName: string, country?: Country) => {
    const page = (pageName === 'home' ? '/' : `/${pageName}`) as PageKeys;

    return isFeatureVisible(
      pages?.[page],
      env,
      country?.code || countryCode,
      userRoles,
    );
  };

  // Handle features
  useEffect(() => {
    const parsedFeatures = mapValues(featureConfiguration, (feature) =>
      isFeatureVisible(feature, env, countryCode, userRoles),
    );
    setFeatures(parsedFeatures);
  }, [countryCode, env, featureConfiguration, userRoles]);

  const userRoleConfig = () => {
    if (!configuration.UserRoleConfig) return;

    const userRole = configuration.UserRoleConfig.filter((role) =>
      role.environments.includes(env.toLowerCase()),
    );

    return userRole[0] ? userRole[0]?.id : undefined;
  };

  return {
    canViewPage,
    userRoleConfig,
    features,
    configuration,
  };
};
