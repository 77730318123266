import { useCallback, useState } from 'react';

export const useFormState = () => {
  const [isFormDirty, setIsFormDirty] = useState<boolean>(false);

  const onFormDirtyChangeHandler = useCallback((isDirty: boolean) => {
    setIsFormDirty(isDirty);
  }, []);

  return {
    isFormDirty,
    onFormDirtyChangeHandler,
  };
};
