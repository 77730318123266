import { useCallback } from 'react';
import {
  DocumentNode,
  OperationVariables,
  QueryOptions,
  useApolloClient,
} from '@apollo/client';

// Using this hook to let an apollo query be returned as a Promise, needed to work with Remote Data in Data Tables.

export const usePromisifiedQuery = <
  TData = any,
  TVariables extends OperationVariables = {},
>(
  query: DocumentNode,
) => {
  const client = useApolloClient();
  return useCallback(
    ({ ...args }: Omit<QueryOptions<TVariables>, 'query'>) =>
      client.query<TData, TVariables>({
        query,
        ...args,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [client],
  );
};
