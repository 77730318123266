import { getEnvironmentVariable } from '@dc3/utils/constants';
import { plugins, externalPlugins } from './index';

const environment = (getEnvironmentVariable('ENV') ?? 'dev')?.toUpperCase();
const qualtricsDiv = `<!--DO NOT REMOVE-CONTENTS PLACED HERE-->`;

export const ThirdPartyProvider = () => {
  if (environment === 'DEV') {
    return null;
  }

  return (
    <>
      {externalPlugins.map((externalPlugin, externalPluginIndex) => (
        <script key={externalPluginIndex} src={externalPlugin} async />
      ))}

      {plugins.map((plugin, pluginIndex) => (
        <script
          key={pluginIndex}
          dangerouslySetInnerHTML={{
            __html: plugin,
          }}
          async
        />
      ))}
      {/* This div is part of qualtrics */}
      <div
        id="ZN_88HSFMzJsbhzaFE"
        dangerouslySetInnerHTML={{ __html: qualtricsDiv }}
      />
    </>
  );
};
