/**
 * INSTRUCTIONS
 *
 * You can supply different configurations for 'pages' and 'features' props.
 * You can enable or disable a feature based on:
 *
 *   - Boolean values
 ------------------------------------
 feature: true,
 ------------------------------------

 *   - Certain environments, f.i. only enable a feature on DEV and STG. (For all countries):
 ------------------------------------
 feature:[{
          environments: ['dev', 'stg'],
        }],
 ------------------------------------

 *   - Environments and countries, f.i. only enable a feature on DEV and STG for 'NL' and 'DE':
 ------------------------------------
 feature:[{
          environments: ['dev', 'stg'],
          countries: ['NL', 'DE'],
        }]
 ------------------------------------

 *   - Different countries per environment, f.i. enable a feature on DEV and STG for 'NL' and 'DE' but on PRD only enable it for NL :
 ------------------------------------
 feature:[{
          environments: ['dev', 'stg'],
          countries: ['NL', 'DE'],
        },
 {
          environments: ["prd"],
          countries: ["NL"],
        }]
 ------------------------------------
 */

export default {
  pages: {
    '/': [
      {
        environments: ['dev', 'stg', 'prd'],
      },
    ],
    '/catalog': [
      {
        environments: ['dev', 'stg', 'prd'],
      },
    ],
    '/edith': [
      {
        environments: ['dev'],
      },
    ],
    '/settings': [
      {
        environments: ['dev', 'stg', 'prd'],
      },
    ],
    '/organization': [
      {
        environments: ['dev', 'stg', 'prd'],
      },
    ],
    '/search': [
      {
        environments: ['dev', 'stg', 'prd'],
      },
    ],
    '/feature-toggles': [
      {
        environments: ['dev', 'stg', 'prd'],
      },
    ],
    '/experimentation': [
      {
        environments: ['dev', 'stg', 'prd'],
      },
    ],
  },
  features: {
    countrySelector: false,
    impersonation: false,
    socialIdentityCreation: false,
    userAddressManagement: false,
    userSettingsManagement: false,
    catalogUploadAssets: [
      {
        environments: ['dev', 'stg', 'prd'],
      },
    ],
    catalogUnmapped: [
      {
        environments: ['dev', 'stg', 'prd'],
      },
    ],
  },
  configuration: {
    draftCatalogId: 'maykersMainCatalog',
    webshopLanguages: ['en', 'da', 'fr'],
    organization: {
      propertiesOnSearch: [],
      propertiesOnQuotation: [],
      propertiesIconMap: {},
    },
  },
};
