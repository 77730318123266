import { GQLIntent } from '@dc3/data-access/graphql';
import { useLogIntentMutation } from './gql/log-intent.generated';

import { ClientIdKey } from './use-init-functional-logging';
import Cookies from 'js-cookie';
import { AUTH_TOKEN_KEY, DC3_LOCALE } from '@dc3/utils/constants';
import { useCallback } from 'react';

class CustomError extends Error {
  originalError?: Error = undefined;

  constructor(message: string, originalError?: Error) {
    super(message);
    this.originalError = originalError;
  }
}

export class FunctionalLoggingNoClientIdError extends CustomError {}

export const useLogIntentHook = () => {
  // send the mutation
  const [logIntentMutation] = useLogIntentMutation();

  const logIntent = useCallback(async (intent: GQLIntent) => {
    const userLocale = Cookies.get(DC3_LOCALE);
    const token = Cookies.get(AUTH_TOKEN_KEY);
    if (!userLocale || !token) {
      return;
    }

    if (typeof window === 'undefined') {
      return Promise.reject('[Functional Logging] window is not defined');
    }

    const clientId = window.localStorage.getItem(ClientIdKey);
    if (!clientId) {
      return Promise.reject(
        '[Functional Logging] client ID is not set in localStorage.',
      );
    }

    return logIntentMutation({
      variables: {
        intent,
      },
      //avoid mutation from re-rendering the component
      ignoreResults: true,
      context: {
        headers: {
          'x-client-instance-id': clientId,
          'x-intent': intent.event_name,
        },
      },
    });
  }, []);

  return {
    logIntent,
  };
};
