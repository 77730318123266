import styled from '@emotion/styled';
import { Chip } from '@mui/material';

export const StyledChip = styled(Chip)`
  background-color: ${({ theme }) => theme.palette.success.main};
  color: ${({ theme }) => theme.palette.secondary.contrastText};
  text-transform: uppercase;

  .MuiIcon-root {
    color: ${({ theme }) => theme.palette.secondary.contrastText};
    font-size: 18px;
  }
`;
