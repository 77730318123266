import { Icon } from '@dc3/ui/icon';
import React from 'react';
import { Button } from '../button/Button';

interface Props {
  handleOnFileLoad: (event: React.ChangeEvent<HTMLInputElement>) => void;
  accept: string;
  title: string;
  icon: string;
}

export const FileUploadButton = ({
  handleOnFileLoad,
  accept,
  title,
  icon,
}: Props) => (
  <>
    <input
      hidden
      type="file"
      accept={accept}
      id="upload-image-button"
      onChange={handleOnFileLoad}
    />
    <label htmlFor="upload-image-button">
      <Button component="span" startIcon={<Icon>{icon}</Icon>}>
        {title}
      </Button>
    </label>
  </>
);
