import { NextPageContext } from 'next';
import { updateToken } from './refresh-token';
import { GRAPHQL_TOKEN_KEY } from '@dc3/utils/constants';
import {
  initApolloClient,
  getHeadersFromContext,
} from '@dc3/data-access/graphql';
import {
  GetPermissionsQueryResult,
  GetPermissionsDocument as permissionsQuery,
} from './gql/permission.generated';

export const getPermissionsMiddleware = async (
  ctx: NextPageContext,
  { idToken, refreshToken }: { idToken: string; refreshToken: string },
) => {
  if (!idToken) {
    return [];
  }
  const contextHeaders = getHeadersFromContext(ctx);

  // prevent context to use outdated cookies
  const headers = {
    ...contextHeaders,
    cookie: updateToken(contextHeaders.cookie, idToken, refreshToken),
    [GRAPHQL_TOKEN_KEY]: idToken,
  };

  const client = initApolloClient({}, ctx, headers);

  try {
    const { data, error }: Partial<GetPermissionsQueryResult> =
      await client.query({
        query: permissionsQuery,
        errorPolicy: 'all',
      });

    let permissions: string[] = [];

    if (data?.permissions?.length) {
      permissions = data.permissions.reduce(
        (acc: string[], val: { permissions: string[] }) =>
          acc.concat(val?.permissions).filter(Boolean),
        [],
      );
    }

    if (!permissions.length && error) {
      throw error;
    }

    return permissions;
  } catch (error) {
    console.error(JSON.stringify(error, null, 2));
    return [];
  }
};
