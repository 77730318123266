import { ThemeProps } from '@dc3/utils/theme';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

type StyledFlagProps = ThemeProps & {
  isMenuItem: boolean;
  isDarkMode: boolean;
};

export const StyledFlag = styled('img', {
  // prevent DOM error unknown prop error
  shouldForwardProp: (prop) =>
    !['isMenuItem', 'isDarkMode'].includes(prop as string),
})`
  ${({ theme, isMenuItem, isDarkMode }: StyledFlagProps) => css`
    /* white border for dark backgrounds, grey for white backgrounds */
    border: 1px solid
      ${!isMenuItem || isDarkMode
        ? theme.palette.grey[300]
        : theme.palette.text.secondary};
    border-radius: 2px;
  `}
`;
