//eslint-disable-next-line
export const debounce = <T extends (...args: any[]) => any>(
  cb: T,
  wait = 450,
) => {
  let timeout: ReturnType<typeof setTimeout> | null = null;

  const callable = (...args: Parameters<T>) => {
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }

    timeout = setTimeout(() => cb(...args), wait);
  };

  return callable as (...args: Parameters<T>) => ReturnType<T>;
};
