import React from 'react';
import { useI18n } from '@dc3/utils/hooks';
import { Button, IconButton } from '../button/Button';
import { Tooltip } from '@dc3/ui/tooltip';
import { Icon } from '@dc3/ui/icon';
interface FeedbackButtonProps {
  iconMode: boolean;
  onClick: () => void;
}

export const FeedbackButton: React.VFC<FeedbackButtonProps> = ({
  iconMode,
  onClick,
}) => {
  const translate = useI18n('global.feedback');
  const title = translate('title');

  return (
    <>
      {iconMode && (
        <Tooltip variant="secondary" placement="right-start" title={title}>
          <IconButton onClick={onClick}>
            <Icon>feedback</Icon>
          </IconButton>
        </Tooltip>
      )}

      {!iconMode && (
        <Button disableElevation color="primary" onClick={onClick}>
          {title}
        </Button>
      )}
    </>
  );
};
