import styled from '@emotion/styled';

export const StyledIndex = styled.div`
  font-family: sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;
export const StyledWrapper = styled.div`
  font-family: sans-serif;
  display: flex;
  flex-direction: row;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.3) 0 1px 3px -1px;
  min-height: 500px;
  border-radius: 20px;
  align-items: center;
  background: linear-gradient(119deg, #131319 5%, #101110 22%, #306375 100%);
`;
