export const defaultProposalFromEmails = [
  // Greece
  { countryCode: 'GR', emails: ['info.gr@kramp.com'] },

  // Finland
  {
    countryCode: 'FI',
    emails: [
      'asiakaspalvelu.fi@kramp.com',
      'tuote1@kramp.com',
      'tuote2@kramp.com',
      'tuote3@kramp.com',
    ],
  },

  // Sweden
  {
    countryCode: 'SE',
    emails: ['kramp.se@kramp.com'],
  },

  // Denmark
  {
    countryCode: 'DK',
    emails: [
      'kramp.dk@kramp.com',
      'universal.dk@kramp.com',
      'hydraulik.dk@kramp.com',
      'traktor.dk@kramp.com',
      'transmission.dk@kramp.com',
      'parkogskov.dk@kramp.com',
      'landbrug.dk@kramp.com',
    ],
  },

  // Norway
  {
    countryCode: 'NO',
    emails: ['kramp.no@kramp.com'],
  },

  // Poland
  {
    countryCode: 'PL',
    emails: ['info.pl@kramp.com'],
  },

  // Romania
  {
    countryCode: 'RO',
    emails: ['info.ro@kramp.com'],
  },

  // Hungary
  {
    countryCode: 'HU',
    emails: ['info.hu@kramp.com'],
  },

  // Czech Republic
  {
    countryCode: 'CZ',
    emails: ['objednavky.cz@kramp.com'],
  },

  // Slovakia
  {
    countryCode: 'SK',
    emails: ['objednavky.sk@kramp.com'],
  },

  // Slovenia
  {
    countryCode: 'SI',
    emails: ['info.si@kramp.com'],
  },

  // Croatia
  {
    countryCode: 'HR',
    emails: ['info.hr@kramp.com'],
  },

  // Norway
  {
    countryCode: 'NO',
    emails: ['kramp.no@kramp.com'],
  },

  // Spain
  {
    countryCode: 'ES',
    emails: ['ventas.es@kramp.com'],
  },

  // Italy
  {
    countryCode: 'IT',
    emails: [
      'servizioclienti@kramp.com',
      'trattori@kramp.com',
      'attrezzatureagricole@kramp.com',
      'oleodinamica@kramp.com',
      'negozio@kramp.com',
      'giardinaggio@kramp.com',
    ],
  },

  // Australia
  { countryCode: 'AU', emails: ['export@kramp.com'] },

  // Bosnia and Herzegovina
  { countryCode: 'BA', emails: ['export@kramp.com'] },

  // Canada
  { countryCode: 'CA', emails: ['export@kramp.com'] },

  // Chile
  { countryCode: 'CL', emails: ['export@kramp.com'] },

  // Cyprus
  { countryCode: 'CY', emails: ['export@kramp.com'] },

  // Iceland
  { countryCode: 'IS', emails: ['export@kramp.com'] },

  // Japan
  { countryCode: 'JP', emails: ['export@kramp.com'] },

  // Kosovo
  { countryCode: 'XK', emails: ['export@kramp.com'] },

  // Moldova
  { countryCode: 'MD', emails: ['export@kramp.com'] },

  // New Zealand
  { countryCode: 'NZ', emails: ['export@kramp.com'] },

  // North Macedonia
  { countryCode: 'MK', emails: ['export@kramp.com'] },

  // Serbia
  { countryCode: 'RS', emails: ['export@kramp.com'] },

  // Turkey
  { countryCode: 'TR', emails: ['export@kramp.com'] },

  // United Arab Emirates
  { countryCode: 'AE', emails: ['export@kramp.com'] },

  // United States
  { countryCode: 'US', emails: ['export@kramp.com'] },
];
