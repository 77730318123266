import { countries, Country } from '@dc3/utils/tenant-config';

export const getCountries = () =>
  [...countries].sort((a, b) => a.name.localeCompare(b.name));

export const getCountryByField = (
  field: 'code' | 'name' | 'locale' | 'url',
  value?: string,
): Country | undefined =>
  value ? countries.find((c: Country) => c[field] === value) : undefined;

export const getFormattedLanguageCode = (code: string) =>
  getCountryByField('code', code)?.locale.replace('_', '-');
