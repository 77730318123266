import React from 'react';
import { AccessControlContext } from './AccessControlContext';
import { accessControlConfiguration, useUserData } from '@dc3/utils/auth';
import {
  canAccess,
  getFullPathFromRouter,
  shouldByPassAccessControl,
  toggleAccessControlScript,
  getPagePermissions,
} from './AccessControl.utils';
import { useRouter } from 'next/compat/router';
import { useConfiguration } from '../TenantConfiguration';
import { Login } from '@dc3/feature/authentication';
interface AccessControlProviderProps {
  userPermissions?: string[];

  tenant: string;
  fallbackComponent: React.FC<{
    requiredPermissions: string[];
    userPermissions: string[];
  }>;
  children?: React.ReactNode;
}

export const AccessControlProvider = ({
  userPermissions = [],
  tenant,
  fallbackComponent: Forbidden,
  children,
}: AccessControlProviderProps) => {
  const router = useRouter();
  const fullPath = getFullPathFromRouter(router?.basePath, router?.asPath);
  const tenantPermissions = accessControlConfiguration[tenant];

  const pagePermissions = getPagePermissions(
    tenantPermissions?.pageAccessControl,
    fullPath,
  );
  const { userData } = useUserData();

  const canViewPage =
    shouldByPassAccessControl() || canAccess(userPermissions, pagePermissions);
  const { features } = useConfiguration();

  return !features.loginApp ? (
    // old auth flow
    <AccessControlContext.Provider
      value={{ permissions: userPermissions, tenant }}
    >
      {canViewPage ? (
        children
      ) : (
        <Forbidden
          requiredPermissions={pagePermissions}
          userPermissions={userPermissions}
        />
      )}
    </AccessControlContext.Provider>
  ) : // new auth flow
  userData?.userId ? (
    <AccessControlContext.Provider
      value={{ permissions: userPermissions, tenant }}
    >
      {canViewPage ? (
        children
      ) : (
        <Forbidden
          requiredPermissions={pagePermissions}
          userPermissions={userPermissions}
        />
      )}
    </AccessControlContext.Provider>
  ) : (
    <Login router={router} />
  );
};

export { toggleAccessControlScript };
