import { Box, Icon } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { InlineLoading } from '..';
import { StyledChip } from './LoadingAndSavedMessage.styles';
import { useI18n } from '@dc3/utils/hooks';
import { hideSavedStateAfterTimeout } from './LoadingAndSavedMessage.utils';

interface LoadingAndSavedMessageProps {
  isLoading?: boolean;
  // Saved indication will disappear after 4 seconds.
  isSaved?: boolean;
  onHideSavingMessage?: () => void;
}

export const LoadingAndSavedMessage = ({
  isLoading = false,
  isSaved = false,
  onHideSavingMessage,
}: LoadingAndSavedMessageProps) => {
  const translate = useI18n('global');
  const [showSavedState, setShowSavedState] = useState(isSaved);

  useEffect(() => {
    const timer = hideSavedStateAfterTimeout(isSaved, setShowSavedState);
    return () => {
      clearTimeout(timer);
    };
  }, [isSaved]);

  // Alert consumers when hide message is hidden
  useEffect(() => {
    if (isSaved && !showSavedState && onHideSavingMessage) {
      onHideSavingMessage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showSavedState]);

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      {isLoading && <InlineLoading size={20} />}
      {showSavedState && (
        <StyledChip
          size="small"
          icon={<Icon>check</Icon>}
          label={translate('saved')}
        />
      )}
    </Box>
  );
};
