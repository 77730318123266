import decode from 'jwt-decode';
import fetch from 'isomorphic-unfetch';
const REFRESH_ENDPOINT =
  process.env.SWORDFISH_ENDPOINT || 'https://auth-stg.kramp.com';
const REFRESH_ENDPOINT_PATHNAME = '/v1/refreshidtoken';

export interface KrampJwt {
  /** corporate-identity country-code combination */
  ccc: {
    [organizationId: string]: string[];
  };
  /** Account ID eg. 39919575 */
  acc: string;
  /** Username eg. solteqPT100 */
  log: string;
  /** Organization ID eg. C1010_0149686 */
  org: string;
  /** Roles per orgianization */
  rol: {
    [organizationId: string]: number[];
  };
  /** Issuer */
  iss: string;
  /** Audience eg. kramphub-valentio-staging */
  aud: string;
  /** Time when authentication occurred (unix timestamp) */
  auth_time: number;
  /** User ID */
  user_id: string;
  /** Subject */
  sub: string;
  /** Issued at (unix timestamp) */
  iat: number;
  /** Expires at (unix timestamp) */
  exp: number;
  email: string;
  email_verified: boolean;
  firebase: {
    [key: string]: unknown;
    identities?: {
      email: string[];
    };
    sign_in_provider?: string;
  };
}
export const decodeJwt = (jwt: string): KrampJwt => {
  if (!jwt) {
    throw new Error('JWT is missing');
  }

  try {
    return decode(jwt) as KrampJwt;
  } catch (error) {
    throw new Error(`Failed to decode JWT, ${error}`);
  }
};

export const isJwtExpired = (token: string) => {
  try {
    const currentTime = Date.now() / 1000;
    const decodedJWT = decodeJwt(token);
    return decodedJWT.exp < currentTime;
  } catch (error) {
    return true;
  }
};

export const refreshIdToken = async (refreshToken: string) => {
  const response = await fetch(
    `${REFRESH_ENDPOINT}${REFRESH_ENDPOINT_PATHNAME}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        accept: 'application/json',
      },
      body: JSON.stringify({ refreshToken }),
    },
  );
  return response.json();
};

export const updateToken = (
  cookies: string | undefined,
  idToken: string,
  refreshToken: string,
) => {
  if (!cookies) return '';

  return cookies
    .split('; ')
    .filter((item: string) => !/idToken|refreshToken/g.test(item))
    .concat(`idToken=${idToken}`)
    .concat(`refreshToken=${refreshToken}`)
    .join('; ');
};
