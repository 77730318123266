import { NO_DATA } from '@dc3/utils/constants';

export const getFormattedNumber = (value: number, decimals = 2) => {
  if (value === null || value === undefined || isNaN(value)) return NO_DATA;

  const formatter = new Intl.NumberFormat(undefined, {
    style: 'decimal',
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  });

  return formatter.format(value);
};
