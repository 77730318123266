import React from 'react';
import { StyledFlag } from './Flag.styles';
import { useUserData } from '@dc3/utils/auth';
import { ViewModes } from '@dc3/utils/theme';

export interface FlagProps extends React.HTMLAttributes<HTMLImageElement> {
  countryCode: string;
  countryName?: string;
  role?: string;
  width?: number;
  isMenuItem?: boolean;
}

const cdn = 'https://cdn.jsdelivr.net/gh/madebybowtie/FlagKit@2.2/Assets/SVG';

export const Flag = ({
  countryCode,
  countryName,
  role = 'img',
  width,
  isMenuItem = false,
  ...rest
}: FlagProps) => {
  const { viewMode } = useUserData();
  const isDarkMode = viewMode === ViewModes.DC3Dark;

  return (
    <StyledFlag
      src={`${cdn}/${countryCode?.toUpperCase()}.svg`}
      role={role}
      alt={countryName ?? countryCode}
      width={width}
      isMenuItem={isMenuItem}
      isDarkMode={isDarkMode}
      {...rest}
    />
  );
};
