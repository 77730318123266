import styled from '@emotion/styled';
import MaterialAutocomplete from '@mui/material/Autocomplete';

export const StyledAutocomplete = styled(MaterialAutocomplete)`
  width: 100%;

  & .MuiAutocomplete-inputRoot {
    background: ${({ theme }) => theme.palette.background.paper};
  }
` as typeof MaterialAutocomplete;
