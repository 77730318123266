import React, { useState, useCallback } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import { debounce } from '@dc3/utils/helpers';
import { useLazyEffect } from '@dc3/utils/hooks';
import { Icon } from '@dc3/ui/icon';
import { Input, StyledTextFieldProps } from '../input/Input';
import { InlineLoading } from '@dc3/ui/loading';

interface SearchInputProps extends Omit<StyledTextFieldProps, 'onChange'> {
  onChange: (value: string) => void;
  debounced?: boolean;
  showSearchIcon?: boolean;
}

export const SearchInput = ({
  onChange,
  value: initialValue,
  isLoading = false,
  debounced = false,
  showSearchIcon = true,
  ...rest
}: SearchInputProps) => {
  const [value, setValue] = useState<string>(initialValue as string);

  const onValueChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    setValue(target.value);

    if (!debounced) {
      onChange(target.value);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedCallback = useCallback(
    debounce((output: string) => onChange(output)),
    [debounce],
  );

  useLazyEffect(() => {
    if (debounced) {
      debouncedCallback(value);
    }
  }, [value, debouncedCallback]);

  return (
    <Input
      variant="outlined"
      InputProps={{
        startAdornment: showSearchIcon && (
          <InputAdornment position="start">
            <Icon>search</Icon>
          </InputAdornment>
        ),
        endAdornment: isLoading && (
          <InputAdornment position="end" component="span">
            <InlineLoading size={20} />
          </InputAdornment>
        ),
      }}
      name="search-input"
      value={value}
      onChange={onValueChange}
      isLoading={isLoading}
      type="search"
      {...rest}
    />
  );
};
