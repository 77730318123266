export enum Intent {
  ADD_TO_QUOTATION = 'ADD_TO_QUOTATION',
  ITEM_CLICK = 'ITEM_CLICK',
  ITEM_IMPRESSION = 'ITEM_IMPRESSION',
  PAGE_VIEW = 'PAGE_VIEW',
  REMOVE_FROM_QUOTATION = 'REMOVE_FROM_QUOTATION',
  VIEW_QUOTATION = 'VIEW_QUOTATION',
  VIEW_ITEM = 'VIEW_ITEM',
  SUBMIT_QUOTATION = 'SUBMIT_QUOTATION',
}
