import React, { useEffect } from 'react';

/**
 * Hook that triggers a CB Fn if the user clicks outside of the passed ref.
 * usage: useOutsideTrigger<HTMLInputElement>(ref, callback)
 */
export const useOutsideTrigger = <T extends HTMLElement>(
  ref: React.RefObject<T>,
  callback: () => void,
) => {
  useEffect(() => {
    /**
     * Trigger CB if clicked outside of element
     */
    const handleClickOutside = (event: MouseEvent) => {
      if (event) {
        if (ref.current && !ref.current.contains(event.target as Element)) {
          callback();
        }
      }
    };
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, callback]);
};
