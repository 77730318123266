import React, { useState } from 'react';
import { DynamicFieldInterface } from './DynamicForm';
import { Input } from '../input/Input';
import {
  DynamicInputStylesInterface,
  InputWrapper,
} from './DynamicFieldInput.styles';
import { IconButton } from '@dc3/ui/buttons';
import { Icon } from '@dc3/ui/icon';

interface DynamicFieldInputProps {
  index: number;
  dynamicStyle: DynamicInputStylesInterface;
  field: DynamicFieldInterface;
  handleFieldChange: (key: string, value: string) => void;
  removeField: (key: string) => void;
}

export const DynamicFieldInput = ({
  index,
  field,
  handleFieldChange,
  removeField,
  dynamicStyle,
}: DynamicFieldInputProps) => {
  const [touched, setTouched] = useState<boolean>(false);
  return (
    <div key={`field-${index}`}>
      <InputWrapper min_width={dynamicStyle.min_width}>
        <Input
          label={field.key}
          data-index={index}
          name="value"
          value={field.value}
          onChange={(e) => {
            handleFieldChange(field.key, e.target.value);
            setTouched(true);
          }}
          error={touched && !field.value.trim()}
          required={true}
        />
      </InputWrapper>
      <IconButton onClick={() => removeField(field.key)} sx={{ p: 2 }}>
        <Icon>delete</Icon>
      </IconButton>
    </div>
  );
};
