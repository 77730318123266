import { NO_DATA } from '@dc3/utils/constants';

export const getPercentage = (value: number, notFound: string = NO_DATA) => {
  if (!value && value !== 0) return notFound;

  const formatter = new Intl.NumberFormat(undefined, {
    style: 'percent',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });

  return formatter.format(value / 100);
};
