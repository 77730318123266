import React, { useCallback, useEffect } from 'react';
import { NextRouter, Router } from 'next/router';
import {
  getPageType,
  PageTypes,
  useTrackPageViewEvent,
} from '@dc3/utils/functional-logging';
import { makeVar } from '@apollo/client';

interface TrackModel {
  entity_id: string;
  entity_type: string;
}

export const isLoadingTab = makeVar(false);

export const usePageViewTracking = (router: Router) => {
  const trackPageViewEvent = useTrackPageViewEvent();
  const handleRouteChange = (): TrackModel =>
    // get the input data  by splitting the path like organization/detail/LE_94/organization-users where entity_id :LE_94 AND entity_type : organization-users
    Object.keys(router.query).reduce(
      (acc, key): TrackModel => {
        const values = router.query[key] || [router.asPath];
        return {
          entity_id: values[0],
          entity_type: values?.[1] || values[0],
        };
      },
      {
        entity_type: router.asPath.slice(1),
        entity_id: router.asPath.slice(1),
      },
    );
  const handlePageViewEvent = useCallback(() => {
    isLoadingTab(false);
    const pageType = getPageType(
      router.basePath.slice(1).toUpperCase(),
    ) as PageTypes;

    const inputValue = handleRouteChange();
    trackPageViewEvent([inputValue], pageType as PageTypes);
  }, []);

  useEffect(() => {
    // errors are handled with specific type in globalTracking
    if (router.route !== '/_error') {
      handlePageViewEvent();
    }
    //to handle when route is changing
    router.events.on('routeChangeStart', () => isLoadingTab(true));
    router.events.on('routeChangeComplete', handlePageViewEvent);

    return () => {
      router.events.off('routeChangeStart', () => isLoadingTab(true));
      router.events.off('routeChangeComplete', handlePageViewEvent);
    };
  }, []);
};

export const useGlobalTracking = (pageType: PageTypes, router: NextRouter) => {
  const [error, setError] = React.useState(false);
  const trackPageViewEvent = useTrackPageViewEvent();

  useEffect(() => {
    if (!error) {
      // here is the path look like /quotation/overview
      const path = router.asPath.split('/');

      trackPageViewEvent(
        [
          {
            entity_type: path[1],
            entity_id: path?.[2] || path[1],
          },
        ],
        pageType,
      );
      setError(true);
    }
  }, [error, router.asPath, router.basePath, trackPageViewEvent]);
};
