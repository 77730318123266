import { css } from '@emotion/react';

export const globalCSS = css`
  /*
 * Set the global box-sizing state to border-box.
 *
 * css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice
 * paulirish.com/2012/box-sizing-border-box-ftw
 */

  #__next {
    height: 100%;
    /* to prevent scrollbar next to topbar and tabbar */
    overflow: hidden;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    height: 100%;
  }

  a {
    text-decoration: none;
  }

  body {
    height: 100%;
    line-height: 1.2;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'Roboto', sans-serif;
    background-color: #ffffff;

    @media (max-width: 1400px) {
      zoom: 0.9;
    }
  }

  // TinyMCE dark-mode custom change
    .dark-mode {
      .tox:not(.tox-tinymce-inline) .tox-editor-header {
        background-color: #222f3e;
      }
      .tox-tinymce {
        border: 1px solid #ffffff3b;
        border-radius: 1px;
      }
      .tox .tox-toolbar-overlord {
        background-color: #222f3e;
      }
      .tox .tox-toolbar__primary {
        background-color: #222f3e;
      }
      .tox .tox-tbtn {
        background-color: #222f3e;
      }
      .tox .tox-tbtn svg {
        fill: #fff;
      }
      .tox .tox-edit-area__iframe {
        background-color: #222f3e;
      }
      .tox.tox-edit-focus .tox-edit-area::before {
        display: none;
      }
    }

    // TinyMCE light-mode custom change
    .light-mode {
      .tox:not(.tox-tinymce-inline) .tox-editor-header {
        background-color: #fff;
        box-shadow: none;
      }
      .tox-tinymce {
        border: 1px solid #0000003b;
        border-radius: 1px;
      }
      .tox .tox-toolbar-overlord {
        background-color: #fff;
      }
      .tox .tox-toolbar__primary {
        background-color: #fff;
      }
      .tox .tox-tbtn {
        background-color: transparent;
      }
      .tox .tox-edit-area__iframe {
        background-color: #fff;
      }
      .tox.tox-edit-focus .tox-edit-area::before {
        display: none;
      }
    }

  }
`;
