import { flatten } from './flatten';
import en from './translations/en.json';
import enKramp from './translations/tenant/en.kramp.json';
import enMaykers from './translations/tenant/en.maykers.json';

interface Messages {
  [key: string]: string | Messages;
}

type TenantMessages = Record<string, Record<string, Messages>>;
type FlattenedMessages = Record<string, string>;

export const messages: Messages = {
  en: flatten(en),
};

const tenantMessages = {
  en: {
    kramp: flatten<{}>(enKramp),
    maykers: flatten<{}>(enMaykers),
  },
} as TenantMessages;

export const getTranslations = (
  locale?: string,
  tenant = 'kramp',
  defaultLanguage = 'en',
) => {
  const chosenLanguage = locale?.split('_')[0] || '';

  let language = defaultLanguage;
  let translations = messages[defaultLanguage];

  if (chosenLanguage in messages) {
    language = chosenLanguage;
    translations = messages[chosenLanguage];
  }

  const tenantTranslations = tenantMessages[language][tenant];

  const finalMessages = {
    ...(translations as FlattenedMessages),
    ...(tenantTranslations as FlattenedMessages),
  };

  return [language, finalMessages] as [string, Record<string, string>];
};
