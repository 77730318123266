import styled from '@emotion/styled';
import { DateTimePicker as MaterialDateTimePicker } from '@mui/x-date-pickers-pro';

export const StyledDateTimeRangePicker = styled.div`
  width: 400px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export const DateTimePicker = styled(MaterialDateTimePicker)`
  width: 100%;
`;

export const WeekNumber = styled.div`
  font-size: 10px;
  position: absolute;
  left: 12px;
  color: ${({ theme }) => theme.palette.grey[500]};
`;

export const WeekNumberWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
