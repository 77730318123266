/***
 * Use together with 'react-hook-form' package.
 * This component wraps the default Input component inside
 * a Controller component from react-hook-form.
 *  */

import React from 'react';
import { InputWrapper, Input, StyledTextFieldProps } from '../input/Input';
import {
  Control,
  Controller,
  FieldErrors,
  FieldValues,
  FieldPath,
  UseControllerProps,
} from 'react-hook-form';
import { useI18n } from '@dc3/utils/hooks';

export type InputControlledProps<T> = StyledTextFieldProps & {
  name: FieldPath<T>;
  control: Control<T>;
  errors: FieldErrors<T>;
  rules?: UseControllerProps<T>['rules'];
  withWrapper?: boolean;
};

export const InputControlled = <T extends FieldValues>({
  name,
  control,
  errors,
  helperText,
  rules = {},
  variant = 'outlined',
  withWrapper = true,
  ...fieldProps
}: InputControlledProps<T>) => {
  const translate = useI18n();

  const component = (
    <Controller
      name={name}
      rules={rules}
      control={control}
      render={({ field: { value, ref, onChange } }) => (
        <Input
          error={Boolean(errors[name])}
          inputRef={ref}
          helperText={
            helperText ||
            (errors[name as string] && translate('global.fieldRequired'))
          }
          value={value}
          variant={variant}
          onChange={onChange}
          {...fieldProps}
        />
      )}
    />
  );

  return withWrapper ? <InputWrapper>{component}</InputWrapper> : component;
};
