import React from 'react';
import { TooltipProps as MaterialTooltipProps } from '@mui/material/Tooltip';
import { Box } from '@dc3/ui/box';
import { Icon } from '@dc3/ui/icon';
import { Tooltip } from '@dc3/ui/tooltip';

type HelpTooltipProps = Pick<
  MaterialTooltipProps,
  'title' | 'placement' | 'className'
> & {
  children?: React.ReactNode;
};

export const HelpTooltip = ({
  children,
  placement = 'right',
  title,
  className,
}: HelpTooltipProps) => (
  <Box display="flex" flex-wrap="no-wrap" alignItems="center">
    {children}

    {/* Wrap Tooltip children in div to prevent tooltip not working and throwing error: https://github.com/mui-org/material-ui/issues/15903 */}
    <Tooltip title={title} placement={placement} className={className}>
      <Box display="flex">
        <Icon color="action">help</Icon>
      </Box>
    </Tooltip>
  </Box>
);
