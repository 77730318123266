import { createContext } from 'react';

export type NotificationTypes =
  | 'info'
  | 'success'
  | 'error'
  | 'warning'
  | undefined;

export const SnackBarContext = createContext<SnackBarContextProps>({
  snack: null,
  setSnackBar: () => {},
});

export interface SnackInterface {
  text: string;
  type: NotificationTypes;
}

export interface SnackBarContextProps {
  snack: SnackInterface | null;
  setSnackBar: (snack: SnackInterface) => void;
}
