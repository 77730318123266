import { useState, useEffect, useCallback } from 'react';
import debounce from 'lodash/debounce';

export const useDebounce = (value: string, delay = 450) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};

export const useDebounceCallback = <T extends (...args: any[]) => any>(
  callback: T,
  delay = 450,
) => {
  const debouncedFn = useCallback(
    debounce((...args) => callback(...args), delay),
    [delay],
  );
  return debouncedFn;
};
