import { pageViewEvent } from './events';
import { PageTypes } from './page-types';
import { useLogIntentHook } from './use-log-intent.hook';
import { GQLEntityContext } from '@dc3/data-access/graphql';

export const useTrackPageViewEvent = () => {
  const { logIntent } = useLogIntentHook();
  return (
    context: GQLEntityContext[],
    pageType: PageTypes = PageTypes.GENERAL,
  ) => {
    logIntent(pageViewEvent({ entity_context: context }, { pageType }));
  };
};
