import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { ThemeProps } from '@dc3/utils/theme';

const inputWrapperCSS = ({
  min_width,
  theme,
}: DynamicInputStylesInterface & ThemeProps) => css`
  display: inline-flex;
  margin-bottom: ${theme.spacing(3)};
  min-width: ${min_width};
  flex-grow: 0;
  width: calc(100% - 56px);
`;

export const InputWrapper = styled.div`
  ${inputWrapperCSS}
`;

export interface DynamicInputStylesInterface {
  min_width: string;
}
