import React from 'react';
import { Button } from '@dc3/ui/buttons';
import { Box } from '@dc3/ui/box';
import { InputControlled } from '@dc3/ui/form-fields';
import { LoginLayout } from '../../components/layout/Layout';
import { CoreLogin } from '../../../../core';
import { FormProvider, useForm } from 'react-hook-form';
import {
  StyledIndex,
  StyledWrapper,
} from '../../components/layout/Layout.styles';
import { Grow } from '@mui/material';
import { Logo } from '@dc3/ui/logo';
import getConfig from 'next/config';
import InputAdornment from '@mui/material/InputAdornment';
import { Icon } from '@dc3/ui/icon';
import { NextRouter } from 'next/router';
import { StyledLink } from '../../styles/dc3.styles';
import { useI18n } from '@dc3/utils/hooks';

//to use the Login you can import it in _app file of the project
export const Login = ({ router }: { router: NextRouter | null }) => {
  const methods = useForm();
  const translate = useI18n('global');

  const {
    publicRuntimeConfig: { MEDIA_SERVER, SSO_LOGIN_URL },
  } = getConfig();
  return (
    <FormProvider {...methods}>
      <LoginLayout>
        <StyledIndex>
          <StyledWrapper>
            <Box display="flex" justifyContent="center" padding={4}>
              <Grow in={true}>
                <Box component="header" textAlign="center" marginBottom={4}>
                  <Logo
                    url={`${MEDIA_SERVER}/dark_no-subtitle.svg`}
                    alt="DC3 Logo"
                    width={240}
                  />
                </Box>
              </Grow>
            </Box>
            <Box
              p={6}
              width="500px"
              bgcolor="background.paper"
              minHeight={500}
              display="flex"
              flexDirection="column"
              alignItems="stretch"
              justifyContent="center"
              borderRadius="0 20px 20px 0"
            >
              <CoreLogin router={router}>
                {({ formState: { errors }, control }) => (
                  <>
                    <Box marginBottom={2}>
                      <InputControlled
                        label="Username"
                        name="username"
                        errors={errors}
                        control={control}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Icon>person</Icon>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Box>
                    <Box>
                      <InputControlled
                        label="Password"
                        name="password"
                        type="password"
                        autoComplete="current-password"
                        errors={errors}
                        control={control}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Icon>lock</Icon>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Box>
                    <Box>
                      <StyledLink
                        className="text-sm"
                        href={`${SSO_LOGIN_URL}/resetpassword`}
                      >
                        {translate('forgetPassword')}
                      </StyledLink>
                    </Box>
                    <Box display="flex" justifyContent="flex-end">
                      <Button type="submit" size="large">
                        login
                      </Button>
                    </Box>
                  </>
                )}
              </CoreLogin>
            </Box>
          </StyledWrapper>
        </StyledIndex>
      </LoginLayout>
    </FormProvider>
  );
};
export default Login;
