import cache from 'memory-cache';
import { getClient, projectName, secretName } from './secret-manager-client';

type SecretItem = Record<string, string | number | boolean>;
interface Secrets {
  public: SecretItem;
}

// bump the version to clear the cache
const CACHE_KEY = 'dc3-secrets-4';

export const loadSecrets = async () => {
  const cachedSecrets = cache.get(CACHE_KEY);
  let publicKeysAvailable = false;
  if (cachedSecrets) {
    const secrets = JSON.parse(cachedSecrets) as Secrets;
    return secrets;
  }
  while (!publicKeysAvailable) {
    try {
      const client = getClient();

      const [data] = await client.accessSecretVersion({
        name: `${projectName}/secrets/${secretName}/versions/latest`,
      });

      const payload = JSON.parse(data.payload.data.toString());

      const secrets: Secrets = {
        public: payload.client,
      };

      if (secrets.public) {
        // cache the data for 1 day
        cache.put(CACHE_KEY, JSON.stringify(secrets), 1 * 24 * 60 * 60 * 1000);
        publicKeysAvailable = true;
        return secrets;
      }
    } catch (e) {
      console.log('Failed to load secrets', e);
      return { public: {}, private: {} };
    }
  }
};
