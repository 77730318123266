import React from 'react';

interface LogoProps {
  url: string;
  width?: string | number;
  height?: string;
  alt?: string;
}
export const Logo = ({ url, width, height, alt = 'DC³ Logo' }: LogoProps) => (
  <img src={url} height={height} width={width} alt={alt} />
);
