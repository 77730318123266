import getConfig from 'next/config';

const versionRegExp = /\d{1,3}\.\d{1,3}\.\d{1,3}/;

export const getVersionFromTag = (tag: string | undefined) =>
  tag?.match(versionRegExp)?.[0] || '';

export const getAppVersion = () => {
  const {
    publicRuntimeConfig: { TAG_NAME },
  } = getConfig();

  return getVersionFromTag(TAG_NAME);
};
