/* eslint-disable no-useless-escape */
// disable eslint because eslint hates regex;

export const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

// validates standard urls: https or http or www.  | even validates localhost urls
export const urlRegex =
  /^((http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*(\.[a-z0-9]{1,5})|(http:\/\/|https:\/\/)?localhost)(:[0-9]{1,5})?(\/.*)?$/;

// checks a-z a-Z and 0-9
export const alphaNumericalRegex = /^[a-zA-Z0-9]*$/;

// checks a-z, a-Z, 0-9, -, +, _, . and @
export const userNameRegex = /^[a-zA-Z0-9\-_+@\.]*$/;

export const categoryIdRegex = /^[a-z0-9-_]+$/;

const decimalRegex = /^[0-9]*[.]?[0-9]{0,2}$/;

export const validateEmail = (input: string) => emailRegex.test(input);
export const validateUrl = (input: string) => urlRegex.test(input);
export const isDecimalString = (input: string) => decimalRegex.test(input);
