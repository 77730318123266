import React, { forwardRef, useEffect, useState } from 'react';
import TextField, { type TextFieldProps } from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { LoadingAndSavedMessage } from '@dc3/ui/loading';

export { InputWrapper } from './Input.styles';
export { InputAdornment };

export type StyledTextFieldProps = Omit<TextFieldProps, 'ref'> & {
  isLoading?: boolean;
  isSaved?: boolean;
  fullWidth?: boolean;
};

export const Input = forwardRef<HTMLInputElement, StyledTextFieldProps>(
  (
    {
      isLoading = false,
      isSaved = false,
      variant = 'outlined',
      fullWidth = true,
      ...rest
    },
    ref,
  ) => {
    const [showSavedState, setShowSavedState] = useState(isSaved);
    useEffect(() => setShowSavedState(isSaved), [isSaved]);

    const mergedProps: TextFieldProps =
      isLoading || showSavedState
        ? {
            ...rest,
            InputProps: {
              ...rest.InputProps,
              endAdornment: (
                <InputAdornment position="end">
                  <LoadingAndSavedMessage
                    isLoading={isLoading}
                    isSaved={showSavedState}
                    onHideSavingMessage={() => setShowSavedState(false)}
                  />
                </InputAdornment>
              ),
            },
          }
        : rest;

    return (
      <TextField
        fullWidth={fullWidth}
        variant={variant}
        {...mergedProps}
        ref={ref}
      />
    );
  },
);
