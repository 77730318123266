import * as React from 'react';
import { UserDataContext } from './user-data.context';

export const useUserData = () => {
  const {
    userData,
    country,
    viewMode,
    isAuthenticated,
    setViewMode,
    setCountry,
  } = React.useContext(UserDataContext);
  return {
    userData,
    country,
    viewMode,
    isAuthenticated,
    setViewMode,
    setCountry,
  };
};
