import { useState, useEffect } from 'react';
import { isClientSide } from '@dc3/utils/helpers';

interface PersistentItem {
  id: string;
}

export const usePersistedDataList = <Item extends PersistentItem | undefined>(
  key: string,
  maxLength?: number,
) => {
  const [items, setItems] = useState<Item[]>([]);

  // load initial state
  useEffect(() => {
    if (!isClientSide() || !key) return;
    setItems(JSON.parse(localStorage.getItem(key) || '[]'));
  }, [key]);

  const addItem = (item: Item) => {
    const itemExistsInStorage = items.some(
      (persistedItem) => persistedItem.id === item.id,
    );

    if (itemExistsInStorage) return;

    const updatedList = (
      maxLength && items.length >= maxLength ? items.slice(1) : items
    ).concat(item);

    localStorage.setItem(key, JSON.stringify(updatedList));
    setItems(updatedList);
  };

  const deleteItem = (id: string) => {
    const updatedList = items.filter((item) => item.id !== id);

    localStorage.setItem(key, JSON.stringify(updatedList));
    setItems(updatedList);
  };

  return [items, addItem, deleteItem] as const;
};
