export enum PageTypes {
  CATEGORY = 'category',
  QUOTATION = 'quotation',
  SEARCH = 'search',
  PRODUCT_GROUP = 'product-group',
  PRODUCT = 'product',
  FORBIDDEN = 'forbidden',
  NOT_FOUND = 'not-found',
  ERROR = 'error',
  GENERAL = 'general',
  ORGANIZATION = 'organization',
}
export const getPageType = (pageString: string): PageTypes => {
  const pageType = PageTypes[pageString as keyof typeof PageTypes];
  if (pageType === undefined) {
    return PageTypes.GENERAL;
  }
  return pageType;
};
