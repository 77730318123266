import React, { RefObject } from 'react';
import { useCSVReader } from 'react-papaparse';
import { Button } from '../button/Button';
import { useI18n } from '@dc3/utils/hooks';
import { Icon } from '@dc3/ui/icon';

// react-papaparse doesn't actually provide a type for data
// eslint-disable-next-line
export type CSVDataItem = any;

export type CSVButtonRefType = RefObject<HTMLDivElement> & {
  current: {
    open: (e: React.MouseEvent<HTMLElement>) => {};
  };
};

interface Props {
  handleOnFileLoad: (data: CSVDataItem[]) => void;
  mode?: string;
}

export const CsvUploadButton = ({
  handleOnFileLoad,
  mode = 'secondary',
}: Props) => {
  const translate = useI18n();
  const { CSVReader } = useCSVReader();

  return (
    <CSVReader
      onUploadAccepted={handleOnFileLoad}
      noDrag
      accept={'text/plain, text/csv, .csv, .txt'}
    >
      {({ getRootProps }: Record<string, any>) => (
        <Button
          type="button"
          mode={mode}
          startIcon={<Icon>cloud_upload</Icon>}
          {...getRootProps()}
        >
          {translate('global.uploadCSV')}
        </Button>
      )}
    </CSVReader>
  );
};
