import { getWebShopUrl } from '@dc3/utils/webshop';
interface LinkOptions {
  newTab?: boolean;
  toKebabCase?: boolean;
  query?: Record<string, string>;
}
// myString -> my-string
const dashed = (str: string) =>
  str.replace(/([a-zA-Z])(?=[A-Z])/g, '$1-').toLowerCase();

// { linkTo: 'base' } -> `?link-to=base`
const getQueryString = (
  queryObject?: Record<string, string>,
  toKebabCase?: boolean,
) => {
  if (!queryObject) return '';

  const format = (str: string) => (toKebabCase ? dashed(str) : str);

  const queryString = Object.entries(queryObject)
    .map(([name, value]) => `${format(name)}=${encodeURIComponent(value)}`)
    .join('&');

  return `?${queryString}`;
};

const getLink = (base: string, options?: LinkOptions): string => {
  const { query, toKebabCase = false } = options || {};
  const queryString = getQueryString(query, toKebabCase);

  return base + queryString;
};

export const openImpersonationWindow = (
  organizationId: string,
  accountId: string,
  countryUrl: string,
  corporateIdentity: string,
  environment: string,
) => {
  const { location } = window;
  const appName = location.pathname.split('/')[1];
  const path = appName ? `/${appName}` : '';
  const webShopUrl = getWebShopUrl(corporateIdentity, environment);

  const webshopPage = getLink(`https://${webShopUrl}${countryUrl}`, {
    toKebabCase: true,
    query: {
      accountId: accountId,
      organizationId: organizationId,
    },
  });

  // Implement setauthcookies page at consumer app level
  window.open(
    `${location.origin}${path}/setauthcookies?redirectto=${encodeURIComponent(
      webshopPage,
    )}&corporateIdentity=${corporateIdentity}`,
  );
};
