import { NextApiRequest, NextApiResponse } from 'next';
import {
  PRODUCTION,
  AUTH_TOKEN_KEY,
  AUTH_REFRESH_TOKEN_KEY,
} from '@dc3/utils/constants';
import { serialize } from 'cookie';
import { isJwtExpired, refreshIdToken } from '../refresh-token';
const COOKIE_OPTIONS = { httpOnly: true, secure: PRODUCTION, path: '/' };

export const jwtExpiryMiddleWareServer =
  (
    handler: (
      req: NextApiRequest,
      res: NextApiResponse,
    ) => Promise<void | NextApiResponse<any>>,
  ) =>
  async (req: NextApiRequest, res: NextApiResponse) => {
    const cookieValues = req.cookies;
    const idTokenFromCookies = cookieValues[AUTH_TOKEN_KEY];
    const refreshTokenFromCookies = cookieValues[AUTH_REFRESH_TOKEN_KEY];

    if (idTokenFromCookies && refreshTokenFromCookies) {
      if (isJwtExpired(idTokenFromCookies)) {
        const { errors, idToken, refreshToken } = await refreshIdToken(
          refreshTokenFromCookies,
        );
        if (!errors) {
          req.cookies[AUTH_TOKEN_KEY] = idToken;
          req.cookies[AUTH_REFRESH_TOKEN_KEY] = refreshToken;
          res.setHeader('Set-Cookie', [
            serialize(AUTH_TOKEN_KEY, idToken, COOKIE_OPTIONS),
            serialize(AUTH_REFRESH_TOKEN_KEY, refreshToken, COOKIE_OPTIONS),
          ]);
        } else {
          res.send(UNAUTHENTICATED_RESPONSE);
        }
      }
    } else {
      res.send(UNAUTHENTICATED_RESPONSE);
    }
    return handler(req, res);
  };
const UNAUTHENTICATED_RESPONSE = {
  errors: [
    {
      message: '[Unauthenticated Access] Please login again!',
    },
  ],
  data: null,
};
