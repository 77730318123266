import React from 'react';
import MaterialTooltip, {
  TooltipProps as MaterialTooltipProps,
} from '@mui/material/Tooltip';
import { StyledTooltip } from './Tooltip.styles';

interface TooltipProps extends MaterialTooltipProps {
  variant?: string;
}

export const Tooltip = ({ variant = 'primary', ...rest }: TooltipProps) =>
  variant === 'primary' ? (
    <MaterialTooltip {...rest} />
  ) : (
    <StyledTooltip {...rest} />
  );
