import React, { ReactNode, useEffect, useState } from 'react';
import { SelectProps as MaterialSelectProps } from '@mui/material/Select';
import MaterialMenuItem, {
  MenuItemProps as MaterialMenuItemProps,
} from '@mui/material/MenuItem';
import { LoadingAndSavedMessage } from '@dc3/ui/loading';
import InputLabel, { InputLabelProps } from '@mui/material/InputLabel';
import MaterialSelect from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import { Box } from '@dc3/ui/box';
import { useTheme } from '@dc3/utils/theme';

export type { SelectInputProps } from '@mui/material/Select/SelectInput';

interface MenuItemProps extends MaterialMenuItemProps {
  label: string | ReactNode;
}

export interface SelectOption {
  label: string | ReactNode;
  value: MaterialMenuItemProps['value'];
}

export interface SelectProps<T = {}> extends MaterialSelectProps<T> {
  options: MenuItemProps[];
  defaultItem?: string;
  isLoading?: boolean;
  isSaved?: boolean;
  helperText?: string;
  size?: 'small' | 'medium';
  selectTestId?: string;
  optionsTestIdTag?: string;
  inputLabelProps?: InputLabelProps;
}

export const Select = ({
  variant = 'outlined',
  options,
  label,
  value,
  required,
  onChange,
  disabled,
  onBlur,
  onClose,
  error,
  renderValue,
  defaultItem,
  isLoading,
  name,
  helperText,
  className,
  isSaved,
  size = 'medium',
  selectTestId = '',
  optionsTestIdTag = '',
  inputLabelProps,
  sx,
}: SelectProps) => {
  const [showSavedState, setShowSavedState] = useState(isSaved);
  const theme = useTheme();

  useEffect(() => setShowSavedState(isSaved), [isSaved]);
  return (
    <FormControl fullWidth variant={variant} size={size} sx={sx}>
      <InputLabel id={label + '_id'} {...inputLabelProps}>
        {label}
      </InputLabel>
      <MaterialSelect
        labelId={label + '_id'}
        label={label}
        value={value}
        name={name}
        required={required}
        error={error}
        onChange={onChange}
        disabled={disabled}
        onBlur={onBlur}
        onClose={onClose}
        renderValue={renderValue}
        className={className}
        endAdornment={
          <Box
            sx={{
              position: 'absolute',
              right: theme.spacing(1.5),
              backgroundColor: theme.palette.background.paper,
            }}
          >
            <LoadingAndSavedMessage
              isLoading={isLoading}
              isSaved={showSavedState}
              onHideSavingMessage={() => setShowSavedState(false)}
            />
          </Box>
        }
        data-testid={selectTestId}
      >
        {defaultItem && (
          <MaterialMenuItem value={defaultItem}>{defaultItem}</MaterialMenuItem>
        )}
        {options.map((option: MenuItemProps, i) => (
          <MaterialMenuItem
            key={i}
            value={option.value}
            disabled={option.disabled}
            data-testid={optionsTestIdTag + value}
          >
            {option.label}
          </MaterialMenuItem>
        ))}
      </MaterialSelect>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export { FormHelperText, InputLabel, FormControl };
