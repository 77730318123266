import React, { useState, useEffect, useCallback } from 'react';
import MaterialSnackbar, { type SnackbarProps } from '@mui/material/Snackbar';
import MaterialAlert from '@mui/material/Alert';
import { SnackBarContext, SnackInterface } from './SnackBarContext';

export const SnackBarProvider = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  const [snack, setSnackBar] = useState<SnackInterface | null>(null);
  const [snackPack, setSnackPack] = useState<SnackInterface[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [messageInfo, setMessageInfo] = React.useState<SnackInterface | null>(
    null,
  );

  /* When a snack is sent to this provider add it to the snackPack array */
  useEffect(() => {
    if (!snack) return;

    setSnackPack((prev) => [
      ...prev,
      {
        text: snack.text,
        key: new Date().getTime(),
        type: snack.type,
      },
    ]);
  }, [snack]);

  /* Handles showing the Notification and queing them up */
  useEffect(() => {
    if (snackPack.length && !messageInfo) {
      // Set a new snack when we don't have an active one
      setMessageInfo({ ...snackPack[0] });
      setSnackPack((prev) => prev.slice(1));
      setOpen(true);
    } else if (snackPack.length && messageInfo && open) {
      // Close an active snack when a new one is added
      setOpen(false);
    }
  }, [snackPack, messageInfo, open]);

  const contextValue = {
    snack,
    setSnackBar: useCallback((obj: SnackInterface) => setSnackBar(obj), []),
  };

  const handleClose: SnackbarProps['onClose'] = (_, reason) => {
    if (reason !== 'clickaway') {
      setOpen(false);
    }
  };

  return (
    <SnackBarContext.Provider value={contextValue}>
      <MaterialSnackbar
        key={messageInfo?.text}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={open}
        autoHideDuration={5000}
        onClose={handleClose}
        TransitionProps={{ onExited: () => setMessageInfo(null) }}
      >
        <MaterialAlert
          elevation={6}
          variant="filled"
          action={null}
          onClose={() => setOpen(false)}
          severity={messageInfo?.type}
        >
          {messageInfo?.text}
        </MaterialAlert>
      </MaterialSnackbar>

      {children}
    </SnackBarContext.Provider>
  );
};
