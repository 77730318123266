import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
interface ErrorHandlerProviderProps {
  fallbackComponent: React.FC<any>;
  children?: React.ReactNode;
}

const BreakTheApp = () => {
  throw new Error('Entire app is broken');
};
export const ErrorHandlerProvider = ({
  fallbackComponent: FallbackComponent,
  children,
}: ErrorHandlerProviderProps) => {
  const [breakApp, setBreakApp] = React.useState(false);

  return (
    <ErrorBoundary
      FallbackComponent={({ error, resetErrorBoundary }) => (
        <FallbackComponent
          error={error}
          onRetry={resetErrorBoundary}
          showHomeButton
          reportable
        />
      )}
      onReset={() => setBreakApp(false)}
      resetKeys={[breakApp]}
    >
      {breakApp ? <BreakTheApp /> : null}
      {children}
    </ErrorBoundary>
  );
};
