import Cookies from 'js-cookie';

type Configuration = Record<string, Record<string, string>>;

interface MockLoginProps {
  username: string;
  password: string;
  tenant?: string;
  loginUrl?: string;
  redirectUrl?: string;
}

export const mockLogin = async ({
  username,
  password,
  tenant = 'kramp',
  loginUrl = 'https://auth-stg.kramp.com/v1/login',
  redirectUrl = '',
}: MockLoginProps) => {
  const headersPerTenant: Configuration = {
    maykers: {
      'ctx-corporate-identity': 'maykers',
    },
    kramp: {},
  };

  const headers = headersPerTenant[tenant];

  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...headers },
    body: JSON.stringify({
      username,
      password,
      redirectUrl,
    }),
  };

  const request = await fetch(loginUrl, options);
  const { idToken, refreshToken } = await request.json();

  const path = '/';

  Cookies.set('idToken', idToken, { path });
  Cookies.set('refreshToken', refreshToken, { path });
};
