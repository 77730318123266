import { useContext, useState } from 'react';
import { useLazyEffect } from '@dc3/utils/hooks';
import { canAccess, shouldByPassAccessControl } from './AccessControl.utils';
import { AccessControlContext } from './AccessControlContext';

export const useAccessControl = (requiredPermissions: string[]) => {
  const { permissions } = useContext(AccessControlContext);
  const shouldByPass = shouldByPassAccessControl();

  const [result, setResult] = useState(
    canAccess(permissions, requiredPermissions),
  );

  useLazyEffect(() => {
    setResult(canAccess(permissions, requiredPermissions));
  }, [requiredPermissions]);

  return shouldByPass ? true : result;
};
