import { createTheme, Theme } from '@mui/material/styles';
export { lighten, darken } from '@mui/system';

export const getMaterialTheme = (palette: Theme['palette']) =>
  createTheme({
    palette,
    // palette: {
    //   primary: {
    //     main: '#E6E6FA',
    //   },
    //   secondary: {
    //     main: '#E6E6FA	',
    //   },
    // },
    shape: { borderRadius: 4 },
    components: {
      MuiDialog: {
        styleOverrides: {
          paperWidthXl: {
            maxWidth: 1800,
          },
        },
      },
    },
  });
