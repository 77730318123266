import React from 'react';
import MaterialSwitch, {
  SwitchProps as MaterialSwitchProps,
} from '@mui/material/Switch';
import MaterialFormControlLabel from '@mui/material/FormControlLabel';
import { InlineLoading } from '@dc3/ui/loading';
import { ConditionalWrap } from '@dc3/utils/helpers';
import { HelpTooltip } from '@dc3/ui/help-tooltip';
export { SwitchWrapper } from './Switch.styles';

export interface SwitchProps extends MaterialSwitchProps {
  label: string;
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => void;
  checked?: boolean;
  disabled?: boolean;
  color?: 'primary' | 'secondary' | 'default';
  isLoading?: boolean;
  name?: string;
  labelPlacement?: 'end' | 'start' | 'top' | 'bottom';
  helpIconTooltipText?: string;
}

export const Switch = ({
  label,
  onChange,
  checked = false,
  disabled = false,
  isLoading = false,
  color = 'primary',
  labelPlacement,
  helpIconTooltipText,
  ...rest
}: SwitchProps) => (
  <>
    <ConditionalWrap
      condition={Boolean(helpIconTooltipText)}
      wrap={(children) => (
        <HelpTooltip title={helpIconTooltipText}>{children}</HelpTooltip>
      )}
    >
      <MaterialFormControlLabel
        control={
          <MaterialSwitch
            checked={checked}
            onChange={onChange}
            color={color}
            disabled={disabled || isLoading}
            {...rest}
          />
        }
        labelPlacement={labelPlacement}
        label={label}
      />
    </ConditionalWrap>

    {isLoading && <InlineLoading size={20} />}
  </>
);
