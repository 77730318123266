import styled from '@emotion/styled';

export const StyledLoading = styled.div`
  ${({ theme }) => theme.flexHelpers.centerAll};
  color: ${({ theme }) => theme.palette.text.primary};
  height: 100%;
  min-height: 120px;
  flex-direction: column;
`;
export const LoadingBox = styled.div`
  max-width: 380px;
  text-align: center;
  padding: ${({ theme }) => theme.spacing(2)};
`;
