import { NO_DATA } from '@dc3/utils/constants';

interface MonetaryValue {
  value: number;
  currency: string;
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
}

export const getCurrencyWithValue = (
  monetaryValue: MonetaryValue,
  notFound: string = NO_DATA,
  minimumFractionDigits = 2,
  maximumFractionDigits = 2,
) => {
  const inputIsValid =
    (Boolean(monetaryValue?.value) || monetaryValue?.value === 0) &&
    Boolean(monetaryValue.currency);

  if (!inputIsValid) return notFound || null;

  // numberFormat will default numbers and strings to two decimal places

  const formatter = new Intl.NumberFormat(undefined, {
    style: 'currency',
    currency: monetaryValue.currency,
    minimumFractionDigits: minimumFractionDigits,
    maximumFractionDigits: maximumFractionDigits,
  });

  return formatter.format(monetaryValue.value);
};
