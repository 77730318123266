import { useEffect } from 'react';

import { v4 as uuidV4 } from 'uuid';

export const ClientIdKey = 'x-client-instance-id';

export const useInitFunctionalLogging = (): void => {
  useEffect(() => {
    // check if a client-id is already set
    let clientId = window.localStorage.getItem(ClientIdKey);

    if (clientId === null) {
      // if not, generate a client-id
      clientId = uuidV4();
      window.localStorage.setItem(ClientIdKey, clientId);
    }
  }, []);
};
